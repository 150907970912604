import { Typography } from "@material-ui/core";
import React, { FC } from "react";
import { withRouter } from "react-router-dom";

import Logo from "../Logo";
import TitleSection from "../TitleSection";
import Props from "./Props";
import { useStyles } from "./styles";

const UnderConstruction: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { toggleSidebar } = props;

  return (
    <div className={classes.content}>
      <TitleSection
        toggleSidebar={toggleSidebar}
        noTopMargin
        className={classes.topNav}
      />
      <Logo width={200} height={200} />
      <Typography className={classes.text}>
        This page is currently under construction
      </Typography>
    </div>
  );
};

// @ts-ignore
export default withRouter(UnderConstruction);
