import React from "react";

import { ReactComponent } from "*.svg";

import { ReactComponent as AboutIcon } from "../../../../common/svg/icons/about.svg";
import { ReactComponent as HomeIcon } from "../../../../common/svg/icons/home.svg";
import { ReactComponent as SettingsIcon } from "../../../../common/svg/icons/settings.svg";
import { Route } from "../../../../config/router";

export interface Page {
  route?: Route;
  icon: typeof ReactComponent;
  iconActive: typeof ReactComponent;
  order?: number;
  label: string;
  onClick?: () => void | Promise<any>;
  iconStyles?: React.CSSProperties;
  labelClassName?: string;
  disabled?: boolean;
}

export const topPages: Page[] = [
  {
    route: Route.Dashboard,
    icon: HomeIcon,
    iconActive: HomeIcon,
    label: "Dashboard",
  },
  {
    route: Route.Settings,
    icon: SettingsIcon,
    iconActive: SettingsIcon,
    label: "Settings",
  },
];

export const bottomPages: Page[] = [
  {
    route: Route.About,
    icon: AboutIcon,
    iconActive: AboutIcon,
    label: "About",
  },
];
