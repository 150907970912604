import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import { CUSTOMERS } from "../../common/api/customers/requests";
import { setCustomers } from "../../common/helpers";
import { useRestApi } from "../../common/hooks/useRestApi";
import { store } from "../../store";
import DashboardData from "./components/DashboardData";
import EmptyDashboard from "./components/EmptyDashboard";
import GuestModal from "./components/GuestModal";
import { QueueItem } from "./models";
import Props from "./Props";

const Dashboard: FC<Props> = (props: Props) => {
  const [editingCustomer, setEditingCustomer] =
    useState<QueueItem | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const { state, dispatch } = useContext(store);
  const { toggleSidebar } = props;

  const [customers, loadCustomers] = useRestApi<QueueItem[]>(
    CUSTOMERS(state.venueId as number),
    "GET",
    undefined,
    { initialLoading: true }
  );

  const handleLoadCustomers = useCallback(async () => {
    const data = await loadCustomers();
    setCustomers(data, dispatch);
  }, [dispatch, loadCustomers]);

  useEffect(() => {
    if (state.settings) {
      handleLoadCustomers();
    }
  }, [handleLoadCustomers, state.settings]);

  return (
    <>
      <GuestModal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
          setEditingCustomer(null);
        }}
        customer={editingCustomer}
        handleLoadCustomers={handleLoadCustomers}
      />
      {!!state.customers?.length ? (
        <DashboardData
          data={state.customers}
          loading={customers.loading}
          toggleSidebar={toggleSidebar}
          setEditingCustomer={setEditingCustomer}
          setModalOpen={setModalOpen}
        />
      ) : (
        <EmptyDashboard
          loading={customers.loading}
          toggleSidebar={toggleSidebar}
          setModalOpen={setModalOpen}
        />
      )}
    </>
  );
};

// @ts-ignore
export default withRouter(Dashboard);
