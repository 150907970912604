import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "./models";

export type ClassNames =
  | "flexRowBetweenEnd"
  | "flexRowBetweenCenter"
  | "flexRowStartCenter"
  | "flexColumn"
  | "flexColumnCenterCenter"
  | "flexColumnCenterCenterReverse"
  | "flexRow_Center"
  | "flexRowEndCenter"
  | "width25"
  | "width20"
  | "width10"
  | "width15"
  | "marginAuto";

export const useGlobalStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      flexRowBetweenEnd: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-end",
      },
      flexRowBetweenCenter: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      },
      flexRowStartCenter: {
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
      },
      flexColumn: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
      },
      flexRow_Center: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      },
      flexColumnCenterCenter: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      },
      flexColumnCenterCenterReverse: {
        display: "flex",
        flexDirection: "column-reverse",
        alignItems: "center",
        justifyContent: "center",
      },
      flexRowEndCenter: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
      },
      width25: {
        width: "25%",
      },
      width20: {
        width: "20%",
      },
      width15: {
        width: "15%",
      },
      width10: {
        width: "10%",
      },
      marginAuto: {
        margin: "auto",
      },
    })
);
