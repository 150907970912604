export default {
  app: {
    networkError:
      "Network problem. Please, check your internet connection " +
      "or try again later.",
    permissionsError: "Sorry, you haven't permissions to see that content",
    unauthorizedError: "Session expired. Please Log in to your account.",
    validationError: "Validation error",
  },
  common: {
    delete: "Delete",
    edit: "Edit",
  },
  auth: {
    validationError: "Invalid credentials, please check and try again",
    enterMfaToSignIn:
      "The code was sent to the default MFA device you had selected.",
    forgotPasswordLink: "Forgot password?",
    mfaCodeFieldLabel: "One-Time Code",
    passwordFieldLabel: "Password",
    sendMFACodeButtonText: "Submit",
    cancelMfa: "Cancel",
    logIn: "Log in",
    mfaTitle: "One-Time Passcode",
    toYourAccount: " to Ociety",
    rememberMe: "Authorize this device for 60 days",
    registerLink: "Register here",
    registerQuestion: "Don't have an account?",
    signInButtonText: "Log in",
    emailFieldLabel: "Login",
    blockedError:
      "Your account is blocked due to many wrong login attempts." +
      " We sent you an email with instructions on how to get access back",
    lastAttemptBeforeBlockError:
      "If you enter the password incorrectly again, we will temporarily" +
      " lock your account and send you an email to reset your password",
    inactiveError:
      "Your account is inactive now. Please, contact an Ociety support for more info",
    credentialsError:
      "Invalid credentials. Please, check your email and password and try again",
    deletedError:
      "Your account is deleted. Please contact an Ociety support for more info",
    authError: "Something went wrong... Please, try again later",
  },
  sidebar: {
    dashboardLink: "Dashboard",
  },
  dashboard: {
    addGuest: "Add New Guest",
    searchPlaceholder: "Search name, phone...",
    name: "Name",
    quantity: "Quantity",
    inQueue: "In Queue",
    quoted: "Quoted",
    labels: "Labels",
    areYouSure: "Are You Sure?",
    guestDeleteConfirm: "Are you sure you want to delete guest here?",
    guestCompleteConfirm:
      "This customer has not been notified yet. Are you sure you want to proceed?",
    cancel: "Cancel",
    delete: "Delete",
    beenHere: "(been here {times}x)",
    minLabel: "min",
    proceedWithoutNotification: "Proceed without notification",
    notifyAndComplete: "Notify and complete",
    notifyButtonText: "Notify customer",
    moveToServed: 'Move to "Served"',
    moveToWaiting: 'Move to "Waiting"',
    removeFromList: "Remove from the list",
    editCustomer: "Edit customer",
  },
  guestModal: {
    phoneNumber: "Phone Number",
    phoneError: "Wrong phone number format",
    nameError: "Name is required",
    name: "Name",
    quantityInParty: "Quantity in Party",
    howManyChildren: "How many children?",
    quotedTime: "Quoted wait time (mins)",
    labels: "Labels",
    birthday: "Birthday",
    anniversary: "Anniversary",
    vip: "VIP",
    friendOfOwner: "Friend of Owner",
    hasCoupon: "Has Coupon",
    hasAllergies: "Has Allergies",
    over21: "Over 21",
    vegan: "Vegan/Vegetarian",
    save: "Save",
    cancel: "Cancel",
    addGuest: "Add New Guest",
    editGuest: "Edit Guest",
    wrongFormatErrorText: "Wrong phone number format",
    duplicatePhoneNumbersPopup:
      "Guest with this phone number is already waiting on the waitlist. Please, check the correctness",
    continue: "Continue",
  },
  queueInfo: {
    restaurantWaitlist: "Restaurant Waitlist",
    myPosition: "My Position in Queue",
    myEstimatedTime: "My Estimated Wait Time",
    poweredByOciety: "Powered by Ociety.com",
    menu: "Menu",
    cancel: "Cancel",
    minLabel: "min",
    yesButtonText: "Yes, cancel the order",
    noButtonText: "No, do not cancel",
  },
  settings: {
    waitlistSettings: "Waitlist Settings",
    photo: "Photo",
    change: "Change",
    remove: "Remove",
    save: "Save",
    saved: "Saved!",
    cancel: "Cancel",
    screenLockCode: "Screen Lock PIN Code",
    enterNewPasscode: "Enter new Passcode",
    venueLogo: "Venue Logo",
    selectVenue: "Select Venue",
    uploadMenu: "Upload a digital menu for your venue",
    fileSize: "Up to 25MB in size ",
    uploadFile: "Upload file",
    changeFile: "Change file",
    menu: "Menu",
    resetDay: "Reset daily time",
    resetDayHint:
      "Select time when the guest list will be automatically reset to the following day",
    pendingTooltip:
      "We will check your website and social media to verify changes",
  },
  security: {
    enterPasscode: "Enter Passcode",
    setInitialPasscode: "Set initial PIN code",
    confirmPasscode: "Confirm new PIN code",
    passcode: "Passcode",
  },
};
