import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { colors } from "../../../theme/colors";
import { OcietyTheme } from "../../models";

export type ClassNames =
  | "text"
  | "controls"
  | "cancelButton"
  | "saveButton"
  | "controlsWrapper";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      text: {
        fontSize: theme.typography.pxToRem(15),
        fontFamily: theme.typography.fontFamily,
        margin: theme.spacing(4, 0),
        textAlign: "center",
      },
      controls: {
        fontSize: 14,
      },
      cancelButton: {
        border: `2px solid ${colors.grey.border}`,
        backgroundColor: colors.white.primary,
        color: colors.grey.modalText,
        minWidth: 80,
        marginRight: theme.spacing(2),
      },
      saveButton: {
        minWidth: 100,
      },
      controlsWrapper: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column-reverse",
          "& button": {
            width: "100%",
            marginBottom: theme.spacing(2),
          },
        },
      },
    })
);
