import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";

import Props from "./Props";
import { useStyles } from "./styles";

const BasicModal = (props: Props) => {
  const {
    title,
    closeButton,
    open,
    onClose,
    children,
    className,
    classes: MuiClasses,
    disableBackdropClick,
    ...rest
  } = props;
  const classes = useStyles();

  return (
    <Dialog
      disableBackdropClick
      onClose={onClose}
      open={open}
      className={className}
      classes={MuiClasses}
      {...rest}>
      <DialogTitle disableTypography className={classes.titleWrapper}>
        {title && <Typography className={classes.title}>{title}</Typography>}
        {closeButton && (
          <IconButton className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  );
};

export default BasicModal;
