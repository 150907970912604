import { Grid, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import clsx from "clsx";
import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DroppableProvided,
  DropResult,
} from "react-beautiful-dnd";

import {
  CustomerRequest,
  CustomersRequest,
} from "../../../../../../common/api/customers/models";
import {
  CUSTOMER,
  CUSTOMER_COMPLETE,
  CUSTOMER_NOTIFY,
  CUSTOMERS,
} from "../../../../../../common/api/customers/requests";
import ButtonPrimary from "../../../../../../common/components/ButtonPrimary";
import ConfirmationModal from "../../../../../../common/components/ConfirmationModal";
import {
  reorderArray,
  setCustomers as setCustomersList,
} from "../../../../../../common/helpers";
import { useRestApi } from "../../../../../../common/hooks/useRestApi";
import { OcietyTheme } from "../../../../../../common/models";
import { useGlobalStyles } from "../../../../../../common/styles";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { store } from "../../../../../../store";
import {
  QueueItem as IQueueItem,
  QueueItemStatuses,
  WidgetTypes,
} from "../../../../models";
import QueueItem from "./components/QueueItem";
import QueueItemMobile from "./components/QueueItemMobile";
import TableWrapper from "./components/TableWrapper";
import Props from "./Props";
import { useStyles } from "./styles";

const QueueTable: FC<Props> = (props: Props) => {
  const {
    customers: allCustomers,
    activeWidget,
    handleCustomerEdit,
    loading,
  } = props;

  const filterQueueByStatus = (customers: IQueueItem[], status: WidgetTypes) =>
    // @ts-ignore
    customers.filter((item: IQueueItem) => item.status === status);

  const filterAllCustomers = useCallback(
    () => ({
      [WidgetTypes.Waiting]: filterQueueByStatus(
        allCustomers,
        WidgetTypes.Waiting
      ),
      [WidgetTypes.Served]: filterQueueByStatus(
        allCustomers,
        WidgetTypes.Served
      ),
      [WidgetTypes.Cancelled]: filterQueueByStatus(
        allCustomers,
        WidgetTypes.Cancelled
      ),
    }),
    [allCustomers]
  );

  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [deletingCustomer, setDeletingCustomer] = useState<number | null>(null);
  const [completingOrder, setCompletingOrder] =
    useState<IQueueItem | null>(null);
  const [notifyingCustomerId, setNotifyingCustomerId] =
    useState<number | null>(null);
  const [renewingCustomerId, setRenewingCustomerId] =
    useState<number | null>(null);
  const [customers, setCustomers] = useState(filterAllCustomers());
  const [confirmComplete, setConfirmComplete] = useState(false);

  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  const { state, dispatch } = useContext(store);

  const refreshCustomersListWithoutLoadFromServer = useCallback(
    (removedItemId: number) => {
      // @ts-ignore
      const newCustomers = customers[activeWidget]
        .filter(
          // @ts-ignore
          (newCustomer) => newCustomer.id !== removedItemId
        ) // @ts-ignore
        .map((newCustomer, index) => {
          newCustomer.queuePosition = index + 1;

          return newCustomer;
        });
      setFilteredCustomers(activeWidget, newCustomers);

      return newCustomers;
    },
    [activeWidget, customers]
  );

  useEffect(() => {
    setCustomers(filterAllCustomers());
  }, [allCustomers, filterAllCustomers]);

  const setFilteredCustomers = (status: WidgetTypes, customers: IQueueItem[]) =>
    setCustomers((prevState) => ({ ...prevState, [status]: customers }));

  const [, updateCustomers] = useRestApi<
    Array<IQueueItem>,
    Partial<CustomersRequest>
  >(CUSTOMERS(state.venueId as number), "PUT");

  const onDragEnd = (result: DropResult) => {
    if (
      !result.destination ||
      result.source.index === result.destination.index
    ) {
      return;
    }

    const reordered: IQueueItem[] = reorderArray(
      // @ts-ignore
      customers[activeWidget],
      result.source.index,
      result.destination.index
    );

    const reorderedWithUpdatedPositions = reordered.map((item, index) => {
      item.queuePosition = index + 1;

      return item;
    });
    setFilteredCustomers(activeWidget, reorderedWithUpdatedPositions);
    updateCustomers({ customers: reorderedWithUpdatedPositions }).catch((e) =>
      console.error(e)
    );
  };

  const theme: OcietyTheme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(900));

  const [, updateCustomer] = useRestApi<IQueueItem, Partial<CustomerRequest>>(
    CUSTOMER(state.venueId as number, renewingCustomerId as number),
    "PUT"
  );

  const [{ loading: deletingCustomerRequest }, deleteCustomer] = useRestApi<
    IQueueItem,
    CustomerRequest
  >(CUSTOMER(state.venueId as number, deletingCustomer as number), "DELETE");

  const [{ loading: loadingCustomer }, loadCustomers] = useRestApi<
    IQueueItem[]
  >(CUSTOMERS(state.venueId as number), "GET");

  const [{ loading: completeOrderLoading }, completeOrder] =
    useRestApi<IQueueItem>(
      CUSTOMER_COMPLETE(state.venueId as number, completingOrder?.id as number),
      "POST"
    );

  const [, notifyCustomer] = useRestApi<IQueueItem>(
    CUSTOMER_NOTIFY(state.venueId as number, notifyingCustomerId as number),
    "POST"
  );

  const handleLoadCustomers = useCallback(async () => {
    const data = await loadCustomers();
    setCustomersList(data, dispatch);
  }, [dispatch, loadCustomers]);

  const handleCustomerDelete = (customerId: number) => {
    setOpenConfirmModal(true);
    setDeletingCustomer(customerId);
  };

  const handleCustomerComplete = (item: IQueueItem) => {
    setCompletingOrder(item);
  };

  const handleCustomerNotify = (id: number) => {
    setNotifyingCustomerId(id);
  };

  const handleCustomerRenew = (id: number) => {
    setRenewingCustomerId(id);
  };

  const onComplete = useCallback(async () => {
    const completeData = await completeOrder();
    setCompletingOrder(null);

    if (completeData) {
      const newCustomers: IQueueItem[] =
        refreshCustomersListWithoutLoadFromServer(completeData.id);
      updateCustomers({ customers: newCustomers })
        .then(() => handleLoadCustomers())
        .catch((e) => console.error(e));
      setConfirmComplete(false);
    }
  }, [
    completeOrder,
    handleLoadCustomers,
    refreshCustomersListWithoutLoadFromServer,
    updateCustomers,
  ]);

  const completeOrderRequest = useCallback(async () => {
    setNotifyingCustomerId(null);
    await onComplete();
  }, [onComplete]);

  const notifyCustomerRequest = useCallback(async () => {
    await notifyCustomer();
    setNotifyingCustomerId(null);
    handleLoadCustomers();
  }, [handleLoadCustomers, notifyCustomer]);

  const renewCustomerRequest = useCallback(async () => {
    await updateCustomer({
      status: QueueItemStatuses.Waiting,
      queuePosition: customers[WidgetTypes.Waiting].length + 1,
    });
    setRenewingCustomerId(null);
    handleLoadCustomers();
  }, [customers, handleLoadCustomers, updateCustomer]);

  useEffect(() => {
    if (completingOrder?.id && completingOrder?.isNotified) {
      completeOrderRequest();
    }

    if (completingOrder && !completingOrder?.isNotified) {
      setConfirmComplete(true);
      setNotifyingCustomerId(completingOrder?.id);
    }
  }, [completeOrderRequest, completingOrder]);

  useEffect(() => {
    if (notifyingCustomerId && !completingOrder) {
      notifyCustomerRequest();
    }
  }, [
    completingOrder,
    notifyCustomer,
    notifyCustomerRequest,
    notifyingCustomerId,
  ]);

  useEffect(() => {
    if (renewingCustomerId) {
      renewCustomerRequest();
    }
  }, [renewCustomerRequest, renewingCustomerId]);

  const onConfirmCustomerDelete = async () => {
    await deleteCustomer();

    if (deletingCustomer) {
      const newCustomers =
        refreshCustomersListWithoutLoadFromServer(deletingCustomer);
      updateCustomers({ customers: newCustomers })
        .then(() => handleLoadCustomers())
        .catch((e) => console.error(e));
      handleLoadCustomers();
    }

    setOpenConfirmModal(false);
  };

  const onConfirmCustomerComplete = async () => {
    await completeOrderRequest();
    setConfirmComplete(false);
  };

  const customersCards = useMemo(
    () => (
      <>
        {/* @ts-ignore*/}
        {customers[activeWidget].map((item: IQueueItem, index) => (
          <Draggable
            index={index}
            draggableId={`queue-table-${item.id}`}
            key={item.id}>
            {(providedDraggable: DraggableProvided) =>
              !isMobile ? (
                <QueueItem
                  completing={
                    completingOrder?.isNotified &&
                    completingOrder?.id === item.id
                  }
                  notifying={notifyingCustomerId === item.id}
                  renewing={renewingCustomerId === item.id}
                  key={item.id}
                  item={item}
                  index={item.queuePosition}
                  provided={providedDraggable}
                  handleCustomerEdit={handleCustomerEdit}
                  handleCustomerDelete={handleCustomerDelete}
                  handleCustomerComplete={handleCustomerComplete}
                  handleCustomerNotify={handleCustomerNotify}
                  handleCustomerRenew={handleCustomerRenew}
                />
              ) : (
                <QueueItemMobile
                  notifying={notifyingCustomerId === item.id}
                  completing={
                    completingOrder?.isNotified &&
                    completingOrder?.id === item.id
                  }
                  renewing={renewingCustomerId === item.id}
                  key={item.id}
                  item={item}
                  provided={providedDraggable}
                  handleCustomerEdit={handleCustomerEdit}
                  handleCustomerDelete={handleCustomerDelete}
                  handleCustomerComplete={handleCustomerComplete}
                  handleCustomerNotify={handleCustomerNotify}
                  handleCustomerRenew={handleCustomerRenew}
                />
              )
            }
          </Draggable>
        ))}
      </>
    ),
    [
      activeWidget,
      completingOrder?.id,
      completingOrder?.isNotified,
      customers,
      handleCustomerEdit,
      isMobile,
      notifyingCustomerId,
      renewingCustomerId,
    ]
  );

  const onCompleteConfirmModalClose = () => {
    setConfirmComplete(false);
    setNotifyingCustomerId(null);
    setCompletingOrder(null);
  };

  const notifyAndComplete = async () => {
    if (completingOrder) {
      await notifyCustomer();
      setNotifyingCustomerId(null);

      await onComplete();
    }
  };

  return (
    <Grid container className={classes.container}>
      <ConfirmationModal
        open={openConfirmModal}
        onClose={() => setOpenConfirmModal(false)}
        onConfirm={onConfirmCustomerDelete}
        title={dictionary.dashboard.areYouSure}
        text={dictionary.dashboard.guestDeleteConfirm}
        loading={deletingCustomerRequest}
      />
      <ConfirmationModal
        open={confirmComplete}
        onClose={onCompleteConfirmModalClose}
        title={dictionary.dashboard.areYouSure}
        text={dictionary.dashboard.guestCompleteConfirm}
        loading={completeOrderLoading}
        ModalProps={{ classes: { paper: classes.completeConfirmModal } }}>
        <Grid item xs={12} sm="auto">
          <ButtonPrimary
            onClick={onCompleteConfirmModalClose}
            className={classes.cancelButton}>
            {dictionary.dashboard.cancel}
          </ButtonPrimary>
        </Grid>
        <Grid item xs={12} sm="auto">
          <ButtonPrimary
            loading={loading}
            onClick={notifyAndComplete}
            className={clsx(classes.saveButton, classes.notifyAndComplete)}>
            {dictionary.dashboard.notifyAndComplete}
          </ButtonPrimary>
        </Grid>
        <Grid item xs={12} sm="auto">
          <ButtonPrimary
            loading={loading}
            onClick={onConfirmCustomerComplete}
            className={classes.saveButton}>
            {dictionary.dashboard.proceedWithoutNotification}
          </ButtonPrimary>
        </Grid>
      </ConfirmationModal>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="queue-table">
          {(providedDroppable: DroppableProvided) =>
            !isMobile ? (
              <TableWrapper
                provided={providedDroppable}
                activeWidget={activeWidget}>
                {customersCards}
              </TableWrapper>
            ) : (
              <div
                ref={providedDroppable.innerRef}
                {...providedDroppable.droppableProps}
                className={classes.customersMobileWrapper}>
                {customersCards}
              </div>
            )
          }
        </Droppable>
      </DragDropContext>
    </Grid>
  );
};

export default QueueTable;
