import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { colors } from "../../../../../theme/colors";
import { OcietyTheme } from "../../../../models";

export type ClassNames =
  | "searchBar"
  | "root"
  | "inputRoot"
  | "startAdornment"
  | "notchedOutline"
  | "endAdornment";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      searchBar: {
        fontSize: theme.typography.pxToRem(17),
        fontWeight: 600,
      },
      root: {
        flexGrow: 1,
        [theme.breakpoints.down("sm")]: {
          height: 44,
        },
        caretColor: colors.red.pink,
        height: 50,
      },
      inputRoot: {
        backgroundColor: colors.grey.elementsSecondary,
        height: 50,
      },
      startAdornment: {
        marginRight: theme.spacing(2),
      },
      endAdornment: {
        cursor: "pointer",
      },
      notchedOutline: {
        borderWidth: 1,
        borderColor: `${colors.grey.radio} !important`,
      },
    })
);
