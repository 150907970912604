import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../models";

export type ClassNames = "content" | "text" | "topNav";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      content: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
      },
      text: {
        fontSize: theme.typography.pxToRem(36),
        marginTop: theme.spacing(5),
        fontWeight: 600,
        lineHeight: "unset",
        textAlign: "center",
      },
      topNav: {
        position: "absolute",
        left: theme.spacing(3),
        top: theme.spacing(3),
        margin: 0,
      },
    })
);
