import { AxiosRequestConfig } from "axios";
import { useCallback, useState } from "react";

import { http } from "../../config/api";

export type RequestMethod = "GET" | "POST" | "PUT" | "DELETE";

interface HookConfig {
  disableCache?: boolean;
  initialLoading?: boolean;
  exposeErrorObject?: boolean;
}

type RestApiRequestData = Record<string, any> | undefined;
type RestApiResponseData = Record<string, any> | string | boolean;
type RestApiConfig = AxiosRequestConfig | undefined;

interface PutState {
  loading: boolean;
  error?: string | Error;
  data: RestApiResponseData | null;
}

export function useRestApi<
  T extends RestApiResponseData,
  R extends RestApiRequestData = undefined,
  C extends RestApiConfig = undefined
>(apiPath: string, method: RequestMethod, config?: C, hookConfig?: HookConfig) {
  interface State extends PutState {
    data: T | null;
  }

  const {
    disableCache = true,
    initialLoading = false,
    exposeErrorObject,
  } = hookConfig || {};

  const cachedResponse = disableCache ? null : localStorage.getItem(apiPath);

  const [state, setState] = useState<State>({
    loading: initialLoading,
    data: cachedResponse ? JSON.parse(cachedResponse) : null,
    error: undefined,
  });

  const fetchData = useCallback(
    async (data?: R): Promise<T> => {
      switch (method) {
        case "DELETE": {
          return await http.delete(apiPath, config);
        }
        case "GET": {
          return await http.get(apiPath, config);
        }
        case "POST": {
          return await http.post(apiPath, data, config);
        }
        case "PUT": {
          return await http.put(apiPath, data, config);
        }
      }
    },
    [apiPath, config, method]
  );

  const sendRequest = useCallback(
    async (requestData?: R): Promise<T | undefined> => {
      setState((prevState) => ({ ...prevState, loading: true }));

      try {
        // @ts-ignore
        const { data } = await fetchData(requestData);

        const newState = {
          error: undefined,
          loading: false,
          data,
        };

        setState(newState);

        if (!disableCache) {
          localStorage.setItem(apiPath, JSON.stringify(data));
        }

        return newState.data;
      } catch (e) {
        setState((prevState) => ({
          ...prevState,
          loading: false,
          error: exposeErrorObject ? e : "Something went wrong...",
        }));

        if (exposeErrorObject) return e;
      }
    },
    [fetchData, disableCache, apiPath, exposeErrorObject]
  );

  /**
   * Update state without request to the server
   */
  const updateData = useCallback((data: Record<keyof T, any>) => {
    setState((prevState) => ({
      ...prevState,
      data: {
        // @ts-ignore
        ...prevState.data,
        ...data,
      },
    }));
  }, []);

  return [state, sendRequest, updateData] as const;
}
