import { Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FC, ReactElement } from "react";
import { NavLink, NavLinkProps, useHistory } from "react-router-dom";

import { Route } from "../../../config/router";
import { useStyles } from "./styles";

// @ts-ignore
interface Props extends NavLinkProps {
  to?: Route | Array<Route | string> | string;
  linkTextClassName?: string;
  noEffects?: boolean;
  icon?: ReactElement;
  state?: Record<string, any>;
  fullUrl?: boolean;
  disabled?: boolean;
}

const OcietyNavLink: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const history = useHistory<Record<string, string>>();
  const {
    className,
    children,
    icon,
    linkTextClassName,
    noEffects,
    state,
    to,
    fullUrl,
    disabled,
    ...rest
  } = props;

  if (!to) {
    return (
      // @ts-ignore
      <div
        className={clsx(
          classes.link,
          icon && classes.linkWithIcon,
          className,
          noEffects && classes.noEffects,
          { [classes.disabled]: disabled }
        )}
        {...rest}>
        {icon}
        {typeof children === "string" ? (
          <Typography
            component={"span"}
            className={linkTextClassName}
            variant="body1">
            {children}
          </Typography>
        ) : (
          <div className={linkTextClassName}>{children}</div>
        )}
      </div>
    );
  }

  let toHash = undefined;
  if (Array.isArray(to)) {
    to[0].replace(":id", to[1]);

    if (to[2]) {
      toHash = to[2];
    }
  }

  const pathname = fullUrl
    ? to
    : "/" + (Array.isArray(to) ? to[0].replace(":id", to[1]) : to);

  if (fullUrl) {
    return (
      <a
        className={clsx(
          classes.link,
          icon && classes.linkWithIcon,
          className,
          noEffects && classes.noEffects,
          { [classes.disabled]: disabled }
        )}
        href={pathname as string}
        {...rest}>
        {icon}
        {typeof children === "string" ? (
          <Typography
            component={"span"}
            className={linkTextClassName}
            variant="body1">
            {children}
          </Typography>
        ) : (
          <div className={linkTextClassName}>{children}</div>
        )}
      </a>
    );
  }

  return (
    <NavLink
      className={clsx(
        classes.link,
        icon && classes.linkWithIcon,
        className,
        noEffects && classes.noEffects,
        { [classes.disabled]: disabled }
      )}
      to={{
        pathname: pathname as string,
        hash: toHash,
        state: {
          ...history.location.state,
          ...state,
        },
      }}
      {...rest}>
      {icon}
      {typeof children === "string" ? (
        <Typography
          component={"span"}
          className={linkTextClassName}
          variant="body1">
          {children}
        </Typography>
      ) : (
        <div className={linkTextClassName}>{children}</div>
      )}
    </NavLink>
  );
};

export default OcietyNavLink;
