import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../common/models";

export type ClassNames = "adaptiveWidget";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      adaptiveWidget: {
        width: `calc(100% - ${theme.spacing(6)}px)`,
        margin: theme.spacing(0, 3),
        [theme.breakpoints.down("md")]: {
          justifyContent: "space-between",
          width: `calc(100% - ${theme.spacing(6)}px)`,
        },

        "& > div": {
          marginBottom: theme.spacing(2),
          [theme.breakpoints.up("lg")]: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            "&:first-child": {
              marginLeft: 0,
              marginRight: theme.spacing(1),
            },
            "&:last-child": {
              marginRight: 0,
              marginLeft: theme.spacing(1),
            },
          },
          [theme.breakpoints.down("sm")]: {
            width: `calc(50% - ${theme.spacing(1)}px)`,
            "&:nth-child(odd)": {
              marginRight: theme.spacing(1),
            },
            "&:nth-child(even)": {
              marginLeft: theme.spacing(1),
            },
          },

          "& > div": {
            [theme.breakpoints.down("sm")]: {
              width: "100%",
            },
            [theme.breakpoints.down(445)]: {
              padding: theme.spacing(2),
            },
            [theme.breakpoints.down(375)]: {
              padding: theme.spacing(1),
            },
          },
        },
      },
    })
);
