import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../common/models";
import { colors } from "../../theme/colors";

export type ClassNames =
  | "content"
  | "topNav"
  | "contentCard"
  | "changeButton"
  | "removeButton"
  | "photoSection"
  | "buttonsWrapper"
  | "passcodeInput"
  | "passcodeTextField"
  | "passcodeSection"
  | "saveButton"
  | "passcodeButtonsWrapper"
  | "previewImage"
  | "loader"
  | "select"
  | "selectWrapper"
  | "selectElement"
  | "sectionMargin"
  | "sectionBorderTop"
  | "uploadMenuButton"
  | "uploadMenuButtonWrapper"
  | "menuSection"
  | "menuSectionContainer"
  | "pdfIcon"
  | "menuFileCard"
  | "pdfFileIcon"
  | "menuFileMetadata"
  | "removeMenuButton"
  | "timeIcon"
  | "iconWrapper"
  | "timeInput"
  | "warningIconContainer";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      content: {
        width: "100%",
        height: "100%",
      },
      topNav: {
        margin: 0,
        marginBottom: theme.spacing(3),
        width: "100%",
        justifyContent: "flex-start",
        alignItems: "flex-start",
      },
      contentCard: {
        overflowY: "auto",
        height: "90%",
        padding: theme.spacing(5),
        [theme.breakpoints.down("sm")]: {
          height: "85%",
        },
      },
      changeButton: {
        border: `2px solid ${colors.grey.border}`,
        backgroundColor: `${colors.white.primary} !important`,
        color: colors.grey.modalText,
        marginLeft: theme.spacing(3),
        minWidth: 90,
        [theme.breakpoints.down("xs")]: {
          marginLeft: 0,
          width: "100%",
          marginBottom: theme.spacing(1),
        },
      },
      removeButton: {
        minHeight: 44,
        color: colors.grey.modalText,
        border: "none !important",
        marginLeft: theme.spacing(2),
        minWidth: 90,
        [theme.breakpoints.down("xs")]: {
          marginLeft: 0,
          width: "100%",
        },
      },
      photoSection: {
        color: colors.grey.modalHeader,
        fontSize: theme.typography.pxToRem(20),
        fontFamily: theme.typography.fontFamily,
        fontWeight: 600,
        borderBottom: `1px solid ${colors.grey.border}`,
        width: "100%",
      },
      buttonsWrapper: {
        display: "flex",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(6),
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
          flexDirection: "column",
          marginBottom: theme.spacing(3),
        },
      },
      passcodeInput: {
        color: colors.grey.modalText,
        fontSize: theme.typography.pxToRem(14),
        fontFamily: theme.typography.fontFamily,
        fontWeight: 600,
      },
      passcodeTextField: {
        width: "100%",
        minWidth: 320,
        [theme.breakpoints.down("xs")]: {
          minWidth: "unset",
        },
      },
      passcodeSection: {
        display: "flex",
        flexDirection: "column",
        marginTop: theme.spacing(6),
        color: colors.grey.modalHeader,
        fontSize: theme.typography.pxToRem(20),
        fontFamily: theme.typography.fontFamily,
        fontWeight: 600,
        width: "100%",
      },
      saveButton: {
        width: "100%",
      },
      passcodeButtonsWrapper: {
        marginTop: theme.spacing(2),
        display: "flex",
        alignItems: "center",
      },
      previewImage: {
        borderRadius: "100%",
        width: 56,
        height: 56,
        objectFit: "cover",
        [theme.breakpoints.down("xs")]: {
          maxWidth: "unset",
          maxHeight: "unset",
          width: "25vw",
          height: "25vw",
          marginBottom: theme.spacing(4),
        },
      },
      loader: {
        marginLeft: theme.spacing(2),
      },
      select: {
        paddingTop: 14,
        paddingBottom: 14,
      },
      selectWrapper: {
        marginLeft: "auto",
        width: "100%",
        minWidth: 200,
      },
      selectElement: {
        color: colors.grey.primary,
        fontWeight: 600,
        [theme.breakpoints.up("sm")]: {
          minWidth: 320,
        },
      },
      sectionMargin: {
        margin: theme.spacing(6, 0),
      },
      sectionBorderTop: {
        borderTop: `1px solid ${colors.grey.border}`,
      },
      uploadMenuButton: {
        width: 160,
        marginTop: theme.spacing(3),
        [theme.breakpoints.down("xs")]: {
          width: "100%",
        },
      },
      uploadMenuButtonWrapper: {
        [theme.breakpoints.down("xs")]: {
          width: "100%",
        },
      },
      menuSection: {
        textAlign: "center",
        minHeight: 362,
      },
      menuSectionContainer: {
        height: "100%",
      },
      pdfIcon: {
        marginBottom: theme.spacing(2),
      },
      pdfFileIcon: {
        marginRight: theme.spacing(3),
        height: "100%",
        minWidth: 52,
      },
      menuFileCard: {
        display: "flex",
        minHeight: 110,
        width: "80%",
        [theme.breakpoints.down("md")]: {
          width: "100%",
        },
        maxWidth: 800,
        padding: theme.spacing(3),
        position: "relative",
        margin: theme.spacing(4, 0),
        justifyContent: "flex-start",
      },
      menuFileMetadata: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "space-between",
        overflowX: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      },
      removeMenuButton: {
        zIndex: 1,
        position: "absolute",
        height: 54,
        width: 54,
        backgroundColor: colors.red.lightPink,
        borderRadius: 4,
        top: "25%",
        right: theme.spacing(3),

        "&:hover": {
          backgroundColor: colors.red.lightPinkDarker,
        },
        "& svg > path": {
          fill: colors.red.primary,
        },
      },
      iconWrapper: {
        position: "relative",
      },
      timeIcon: {
        position: "absolute",
        display: "block",
        pointerEvents: "none",
        top: -38,
        right: 20,
      },
      timeInput: {
        cursor: "pointer",
      },
      warningIconContainer: {
        paddingLeft: 24,
        display: "flex",
        alignItems: "center",
      },
    })
);
