import clsx from "clsx";
import dayjs from "dayjs";
import React, { FC, useEffect, useState } from "react";

import { MS_IN_MINUTE } from "../../../../../../../../common/constants";
import { ReactComponent as BellIcon } from "../../../../../../../../common/svg/icons/bell-normal.svg";
import { colors } from "../../../../../../../../theme/colors";
import { QueueItemStatuses } from "../../../../../../models";
import { StatusColors } from "./models";
import Props from "./Props";
import { useStyles } from "./styles";

const StatusBadge: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { status, notified, notifiedAt, className } = props;

  const isActive = status === QueueItemStatuses.Waiting;
  const showNotifiedSign = notified && isActive;

  const [minutesFromNotify, setMinutes] = useState<number>(
    showNotifiedSign ? dayjs().diff(notifiedAt, "minutes") : 0
  );

  const backgroundColor = showNotifiedSign
    ? colors.orange.primary
    : StatusColors[status];

  useEffect(() => {
    if (showNotifiedSign) {
      const interval = setInterval(
        () => setMinutes((prevState) => prevState + 1),
        MS_IN_MINUTE
      );

      return () => clearInterval(interval);
    }
  }, [showNotifiedSign]);

  const text = showNotifiedSign ? `${minutesFromNotify} min` : status;

  return (
    <div
      className={clsx(
        classes.badge,
        { [classes.capitalized]: !showNotifiedSign },
        className
      )}
      style={{ backgroundColor }}>
      {showNotifiedSign && <BellIcon className={classes.bellIcon} />}
      {text}
    </div>
  );
};

export default StatusBadge;
