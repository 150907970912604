import { Grid } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

import dictionary from "../../../i18n/en_US/dictionary";
import { colors } from "../../../theme/colors";
import BasicModal from "../BasicModal";
import ButtonPrimary from "../ButtonPrimary";
import Props from "./Props";
import { useStyles } from "./styles";

const ConfirmationModal = (props: Props) => {
  const {
    onConfirm,
    text,
    title,
    open,
    onClose,
    loading,
    confirmButtonText,
    cancelButtonText,
    className,
    ModalProps,
    classes: externalClasses,
    children,
  } = props;
  const classes = useStyles();

  return (
    <BasicModal
      title={title}
      closeButton
      onClose={onClose}
      open={open}
      className={className}
      {...ModalProps}>
      <div className={classes.text}>{text}</div>
      <Grid container className={classes.controlsWrapper}>
        {children || (
          <>
            <Grid item xs={12} sm="auto">
              <ButtonPrimary
                className={clsx(
                  classes.controls,
                  classes.cancelButton,
                  externalClasses?.cancelButton
                )}
                onClick={onClose}>
                {cancelButtonText || dictionary.dashboard.cancel}
              </ButtonPrimary>
            </Grid>
            <Grid item xs={12} sm="auto">
              <ButtonPrimary
                loading={loading}
                className={clsx(
                  classes.controls,
                  classes.saveButton,
                  externalClasses?.confirmButton
                )}
                onClick={onConfirm}>
                {confirmButtonText || dictionary.dashboard.delete}
              </ButtonPrimary>
            </Grid>
          </>
        )}
      </Grid>
    </BasicModal>
  );
};

export default ConfirmationModal;
