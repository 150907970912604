import { Theme } from "@material-ui/core";

/**
 * @deprecated Use BasicForm interface instead
 */
export interface Form {
  values: Record<string, string>;
  errors: Record<string, string>;
  touched: string[];
  isValid: boolean;
}

export interface BasicForm<T extends string> {
  values: Record<T, string>;
  errors: Record<T, string>;
  touched: T[];
  isValid: boolean;
}

export interface OcietyTheme extends Theme {
  font: {
    size: {
      xxs: number;
      xs: number;
      s: number;
      m: number;
      l: number;
      xl: number;
      xxl: number;
    };
  };
}

export interface UseFormTypes<S, F> {
  form: F;
  setAndValidate: (key: keyof S, value: string) => void;
  validateForm: () => boolean;
  setFormValue: (key: keyof S, value: string) => void;
  setFormValues: (values: { [key in keyof S]: string }) => void;
  setSchemaRules: (key: any, schemaItem: any) => void;
}

type VenueMediaContentTag = "interior" | "exterior" | "menu";

interface VenuePhoto {
  id: number;
  tag: VenueMediaContentTag;
  url: string;
}

export interface Venue {
  id: number;
  name: string;
  photos: VenuePhoto[];
  menu: VenueMenu | null;
}

export interface VenueMenu {
  id: number;
  filePath: string;
  fileName: string;
  size: number;
  active: boolean;
}

export interface Settings {
  isLocked: boolean;
  photoPath: string;
  lockPassword: string;
  // Time format "HH:mm:ss"
  workDayEndTime: string;
  venue?: Venue;
  venues: Venue[];
}

export enum AuthError {
  INVALID_CREDENTIALS = "INVALID_CREDENTIALS",
  ACCOUNT_BLOCKED = "ACCOUNT_BLOCKED",
  ACCOUNT_BEFORE_BLOCKING = "ACCOUNT_BEFORE_BLOCKING",
  ACCOUNT_DELETED = "ACCOUNT_DELETED",
  ACCOUNT_INACTIVE = "ACCOUNT_INACTIVE",
}
