enum Endpoints {
  REFRESH_TOKENS = "auth/refresh",
  LOGIN = "auth/login",
  LOGOUT = "auth/logout",
  VERIFY = "auth/verify",
  SETTINGS = "settings/:venueId",
  SETTINGS_PHOTO = "settings/:venueId/photo",
  SETTINGS_MENU = "settings/:venueId/menu",
  CUSTOMERS = "customers/:venueId",
  CUSTOMER = "customers/:venueId/:customerId",
  CUSTOMER_COMPLETE = "customers/:venueId/:customerId/complete",
  CUSTOMER_NOTIFY = "customers/:venueId/:customerId/notify",
  CUSTOMER_QUEUE_INFO = "customers/:venueId/:customerId/order",
  CUSTOMER_CANCEL = "customers/:customerId/cancel",
  VENUE_LOCK = "settings/:venueId/lock",
  VENUE_UNLOCK = "settings/:venueId/unlock",
}

export default Endpoints;
