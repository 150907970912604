import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { colors } from "../../../theme/colors";
import { OcietyTheme } from "../../models";

export type ClassNames = "root" | "fluid";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)",
      },
      fluid: {
        width: "100%",
      },
    })
);
