import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { ChangeEvent, FC, useState } from "react";

import ButtonPrimary from "../../../../common/components/ButtonPrimary";
import OcietyNavLink from "../../../../common/components/OcietyNavLink";
import TextMessage from "../../../../common/components/TextMessage";
import { forgotPasswordLink, registerLink } from "../../../../common/constants";
import { useSubmitFormOnEnter } from "../../../../common/hooks/useSubmitFormOnEnter";
import dictionary from "../../../../i18n/en_US/dictionary";
import { LoginBasicFormField } from "./form";
import Props from "./Props";

const LoginForm: FC<Props> = (props: Props) => {
  const { classes, rememberMe, toggleRememberMe, handleAuth, loginForm } =
    props;
  const { form, setAndValidate } = loginForm;
  const [authError, setAuthError] = useState<string>("");

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name: key, value } = event.target;
    setAuthError("");
    // @ts-ignore
    setAndValidate(key as LoginBasicFormField, value);
  };

  const { username, password } = form.values;

  const handleSignIn = async () => {
    const error = await handleAuth(username as string, password as string);
    if (error) {
      setAuthError(error);
    }
  };

  useSubmitFormOnEnter(handleSignIn);

  return (
    <>
      {authError && (
        <Box className={classes.errorMessageWrapper}>
          <Box className={classes.errorMessageContent}>
            <TextMessage type="error">{authError}</TextMessage>
          </Box>
        </Box>
      )}
      <div>
        <TextField
          className={classes.textField}
          required
          error={!!form.errors.username}
          fullWidth
          helperText={form.errors.username}
          label={dictionary.auth.emailFieldLabel}
          name="username"
          onChange={handleChange}
          type="text"
          value={form.values.username || ""}
          variant="outlined"
        />
        <TextField
          className={classes.textField}
          required
          error={!!form.errors.password}
          fullWidth
          helperText={form.errors.password}
          label={dictionary.auth.passwordFieldLabel}
          name="password"
          onChange={handleChange}
          type="password"
          value={form.values.password || ""}
          variant="outlined"
        />
      </div>
      <div className={classes.actionsWrapper}>
        <div className={classes.actionsSecondaryContainer}>
          <FormControlLabel
            control={
              <Checkbox
                classes={{
                  checked: classes.checkboxChecked,
                }}
                color="default"
                checked={rememberMe}
                onChange={() => toggleRememberMe()}
                name="rememberMe"
              />
            }
            label={
              <Typography className={classes.checkboxLabel}>
                {dictionary.auth.rememberMe}
              </Typography>
            }
          />
          <OcietyNavLink
            target="_blank"
            className={classes.forgotPasswordLink}
            linkTextClassName={classes.forgotPasswordLinkText}
            fullUrl
            to={forgotPasswordLink}>
            {dictionary.auth.forgotPasswordLink}
          </OcietyNavLink>
        </div>
        <ButtonPrimary
          className={classes.signInButton}
          disabled={!form.isValid || !!authError}
          fullWidth
          onClick={handleSignIn}>
          {dictionary.auth.signInButtonText}
        </ButtonPrimary>
        <div className={classes.registerActionWrapper}>
          <Typography className={classes.registerLabel}>
            {dictionary.auth.registerQuestion}
          </Typography>
          <OcietyNavLink
            target="_blank"
            to={registerLink}
            fullUrl
            className={classes.registerLink}
            linkTextClassName={classes.registerLinkText}>
            {dictionary.auth.registerLink}
          </OcietyNavLink>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
