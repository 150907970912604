import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../common/models";

export type ClassNames = "header" | "contactLink" | "root" | "content";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        minHeight: "100vh",
        flex: 1,
        paddingTop: 72,
      },
      content: {
        minHeight: "100%",
        height: 1,
        [theme.breakpoints.down("sm")]: {
          minHeight: "100%",
        },
        display: "flex",
      },
      header: {
        top: 0,
        left: 0,
        position: "absolute",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.up("md")]: {
          padding: theme.spacing(4, 6, 1),
        },
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(2),
        },
      },
      contactLink: {
        color: theme.palette.text.secondary,
        marginLeft: 12,
      },
    })
);
