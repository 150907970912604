import { IconButton, SvgIcon } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Alert as BaseAlert, AlertTitle, Color } from "@material-ui/lab";
import clsx from "clsx";
import React, { FC, useContext, useEffect, useRef } from "react";

import { ReactComponent as ErrorIcon } from "../../svg/icons/error.svg";
import { ReactComponent as InfoIcon } from "../../svg/icons/info.svg";
import { ReactComponent as SuccessIcon } from "../../svg/icons/success.svg";
import { ReactComponent as WarningIcon } from "../../svg/icons/warning.svg";
import { AlertContext } from "./AlertContextProvider";
import Props from "./Props";
import { useStyles } from "./styles";

const ALERT_LIFETIME = 5000;

const Alert: FC<Props> = (props) => {
  const { hideAlert } = useContext(AlertContext);
  const { children, severity, title, alertId, className, ...rest } = props;
  const isFirstRender = useRef(true);

  const classes = useStyles();

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;

      setTimeout(() => {
        hideAlert(alertId);
      }, ALERT_LIFETIME);
    }
  }, [isFirstRender, hideAlert, alertId]);

  const iconsMapping: Partial<Record<Color, React.ReactElement>> = {
    error: (
      <SvgIcon width="26" height="26" viewBox="0 0 26 26">
        <ErrorIcon />
      </SvgIcon>
    ),
    info: (
      <SvgIcon width="26" height="26" viewBox="0 0 26 26">
        <InfoIcon />
      </SvgIcon>
    ),
    success: (
      <SvgIcon width="26" height="26" viewBox="0 0 26 26">
        <SuccessIcon />
      </SvgIcon>
    ),
    warning: (
      <SvgIcon width="26" height="26" viewBox="0 0 26 26">
        <WarningIcon />
      </SvgIcon>
    ),
  };

  const getDefaultTitle = () => {
    const defaultTitle = severity || "success";

    return (
      defaultTitle.slice(0, 1).toUpperCase() +
      defaultTitle.slice(1, defaultTitle.length)
    );
  };

  // @ts-ignore
  return (
    <BaseAlert
      {...rest}
      severity={severity}
      className={clsx(
        classes.alertWrapper,
        severity && classes[severity],
        className
      )}
      classes={{ icon: classes.icon }}
      iconMapping={iconsMapping}
      action={
        // @ts-ignore
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={() => hideAlert(alertId)}>
          <CloseIcon fontSize="default" color="action" />
        </IconButton>
      }>
      <AlertTitle className={classes.title}>
        {title || getDefaultTitle()}
      </AlertTitle>
      <div className={classes.message}>{children}</div>
    </BaseAlert>
  );
};

export default Alert;
