import dayjs from "dayjs";
import React, { useCallback, useContext, useEffect, useState } from "react";

import { MS_IN_MINUTE } from "../../../../../../../../common/constants";
import { actions, store } from "../../../../../../../../store";
import { LabelsText, QueueItemStatuses } from "../../../../../../models";
import Props from "./Props";

//@ts-ignore
const useQueueItem = (props: Props) => {
  const { item } = props;
  const { queuedAt, quotedTime, labels, status, stoppedAt } = item;
  const { dispatch } = useContext(store);

  const [minutesWaiting, setMinutesWaiting] = useState<number>(
    dayjs(stoppedAt || new Date()).diff(queuedAt, "minutes")
  );

  const isServed = status === QueueItemStatuses.Served;
  const isCancelled = status === QueueItemStatuses.Cancelled;
  const isFinished = isServed || isCancelled;

  const updateWaitTime = useCallback(() => {
    setMinutesWaiting((prevState) => prevState + 1);
    dispatch({
      type: actions.SetWaitingTimes,
      payload: { [item.id]: minutesWaiting + 1 },
    });
  }, [dispatch, item.id, minutesWaiting]);

  useEffect(() => {
    if (!isFinished) {
      const interval = setInterval(updateWaitTime, MS_IN_MINUTE);
      return () => clearInterval(interval);
    }
  }, [isFinished, updateWaitTime]);

  const parseLabels: (labels: string[]) => string[] = () =>
    labels.map((label) => LabelsText[label]);

  const isOvertime: boolean = minutesWaiting > quotedTime;

  const helpers = { parseLabels };
  const values = {
    isOvertime,
    isFinished,
    isServed,
    isCancelled,
    minutesWaiting,
  };

  return {
    helpers,
    values,
  };
};

export default useQueueItem;
