import { Overrides } from "@material-ui/core/styles/overrides";

import MuiMenu from "./MuiMenu";
import MuiMenuItem from "./MuiMenuItem";
import MuiPaper from "./MuiPaper";

const overrides: Overrides = {
  MuiPaper,
  MuiMenu,
  MuiMenuItem,
};

export default overrides;
