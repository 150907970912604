import { Grid } from "@material-ui/core";
import React, { FC, useContext } from "react";

import { ReactComponent as CancelledIcon } from "../../../../../../common/svg/icons/cancelled.svg";
import { ReactComponent as SandclockIcon } from "../../../../../../common/svg/icons/sandclock.svg";
import { ReactComponent as ServedIcon } from "../../../../../../common/svg/icons/served.svg";
import { ReactComponent as WaitingIcon } from "../../../../../../common/svg/icons/waiting.svg";
import { arrayAvg } from "../../../../../../common/utils/objectArrayUtils";
import { store } from "../../../../../../store";
import { colors } from "../../../../../../theme/colors";
import { QueueItemStatuses, WidgetTypes } from "../../../../models";
import DashboardWidgetSmall from "../DashboardWidgetSmall";
import Props from "./Props";
import { useStyles } from "./styles";

const WidgetsPanel: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { activeWidget, handleWidgetClick, customers } = props;
  const { state } = useContext(store);

  const getStatusAmount = (status: QueueItemStatuses) =>
    customers.filter((customer) => customer.status === status).length;

  const avgWait = Math.round(arrayAvg(Object.values(state.customerWaitTimes)));

  return (
    <Grid container classes={{ container: classes.adaptiveWidget }}>
      <Grid item>
        <DashboardWidgetSmall
          color={colors.orange.light}
          icon={WaitingIcon}
          label="Waiting"
          text={getStatusAmount(QueueItemStatuses.Waiting)}
          type={WidgetTypes.Waiting}
          onClick={handleWidgetClick}
          activeWidget={activeWidget}
        />
      </Grid>
      <Grid item>
        <DashboardWidgetSmall
          iconViewBox="0 0 26 20"
          color={colors.blue.light}
          icon={ServedIcon}
          label="Served"
          type={WidgetTypes.Served}
          onClick={handleWidgetClick}
          activeWidget={activeWidget}
          text={getStatusAmount(QueueItemStatuses.Served)}
        />
      </Grid>
      <Grid item>
        <DashboardWidgetSmall
          color={colors.red.pink}
          icon={CancelledIcon}
          label="Cancelled"
          type={WidgetTypes.Cancelled}
          onClick={handleWidgetClick}
          activeWidget={activeWidget}
          text={getStatusAmount(QueueItemStatuses.Cancelled)}
        />
      </Grid>
      <Grid item>
        <DashboardWidgetSmall
          color={colors.blue.violet}
          icon={SandclockIcon}
          label="Avg Wait Time"
          text={`${avgWait} min`}
          type={WidgetTypes.AverageWait}
          activeWidget={activeWidget}
          withoutRadio
        />
      </Grid>
    </Grid>
  );
};

export default WidgetsPanel;
