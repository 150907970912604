enum Actions {
  SetAuthTokens = "SetAuthTokens",
  SetMfaToken = "SetMfaToken",
  SetVenueId = "SetVenueId",
  SetSettings = "SetSettings",
  SetIsLoading = "SetIsLoading",
  SetCustomers = "SetCustomers",
  SetLockingRequest = "SetLockingRequest",
  SetWaitingTimes = "SetWaitingTimes",
  SetQueuePositions = "SetQueuePositions",
}

export default Actions;
