import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";
import { colors } from "../../../../theme/colors";

export type ClassNames =
  | "header"
  | "contentWrapper"
  | "widgets"
  | "venueName"
  | "titleSection"
  | "searchBar"
  | "adaptiveWidget"
  | "burgerMenu"
  | "topNav";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      header: {},
      contentWrapper: {
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      },
      widgets: {
        display: "flex",
      },
      venueName: {
        fontSize: theme.typography.pxToRem(32),
        fontFamily: theme.typography.fontFamily,
        color: colors.grey.primary,
        fontWeight: 700,
      },
      titleSection: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 20,

        "& > div:last-child": {
          [theme.breakpoints.down("xs")]: {
            marginTop: 20,
            width: "100%",
          },
          [theme.breakpoints.down("sm")]: {
            marginTop: 20,
          },
        },
      },
      searchBar: {
        fontSize: theme.typography.pxToRem(17),
        fontWeight: 600,
      },
      searchBarRoot: {
        backgroundColor: colors.grey.inputBackground,
      },
      adaptiveWidget: {
        justifyContent: "space-between",
        [theme.breakpoints.down("xs")]: {
          width: "100%",
        },
        margin: theme.spacing(0),

        "& > div": {
          marginBottom: theme.spacing(2),

          [theme.breakpoints.down("xs")]: {
            width: "100%",
          },

          "& > div": {
            [theme.breakpoints.down("xs")]: {
              width: "100%",
            },
          },
        },
      },
      burgerMenu: {
        marginRight: 10,
        cursor: "pointer",
      },
      topNav: {
        margin: theme.spacing(3),
        width: `calc(100% - ${theme.spacing(6)}px)`,
      },
    })
);
