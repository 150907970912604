import { replaceParams } from "../../helpers";
import Endpoints from "../endpoints";

export const CUSTOMERS = (venueId: number) =>
  replaceParams(Endpoints.CUSTOMERS, { venueId });

export const CUSTOMER = (venueId: number, customerId: number) =>
  replaceParams(Endpoints.CUSTOMER, { venueId, customerId });

export const CUSTOMER_COMPLETE = (venueId: number, customerId: number) =>
  replaceParams(Endpoints.CUSTOMER_COMPLETE, { venueId, customerId });

export const CUSTOMER_NOTIFY = (venueId: number, customerId: number) =>
  replaceParams(Endpoints.CUSTOMER_NOTIFY, { venueId, customerId });

export const CUSTOMER_QUEUE_INFO = (
  venueId: number | string,
  customerId: number | string
) => replaceParams(Endpoints.CUSTOMER_QUEUE_INFO, { customerId, venueId });

export const CUSTOMER_CANCEL = (customerId: number | string) =>
  replaceParams(Endpoints.CUSTOMER_CANCEL, { customerId });
