import { BasicForm } from "../../../../common/models";
import { ValidationRules } from "../../../../common/utils/validation";

export type GuestFormField = "phone" | "name";
export type GuestFormNumberFields =
  | "quantity"
  | "childrenAmount"
  | "quotedTime";
export type GuestFormBooleanFields =
  | "birthday"
  | "vip"
  | "hasCoupon"
  | "anniversary"
  | "friendOfOwner"
  | "hasAllergies"
  | "over21"
  | "vegan";

export type IGuestForm = BasicForm<GuestFormField>;

type GuestFormSchemaType = Record<GuestFormField, ValidationRules>;

export const schema: GuestFormSchemaType = {
  phone: {
    required: true,
    type: "phone",
    length: {
      max: 256,
    },
  },
  name: {
    required: true,
    length: {
      max: 256,
    },
  },
};

export const GuestForm: IGuestForm = {
  errors: {
    phone: "",
    name: "",
  },
  values: {
    phone: "",
    name: "",
  },
  isValid: true,
  touched: [],
};

export type NumberFields = Record<GuestFormNumberFields, number>;
export type BooleanFields = Record<GuestFormBooleanFields, boolean>;

export type FormValues = {
  [key in GuestFormField]: string;
};

export const numberFields: NumberFields = {
  quantity: 1,
  childrenAmount: 0,
  quotedTime: 1,
};

export const booleanFields: BooleanFields = {
  birthday: false,
  vip: false,
  hasCoupon: false,
  anniversary: false,
  friendOfOwner: false,
  hasAllergies: false,
  over21: false,
  vegan: false,
};
