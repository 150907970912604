import { CircularProgress, Grid } from "@material-ui/core";
import clsx from "clsx";
import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import Endpoints from "../../../../common/api/endpoints";
import { SettingsResponse } from "../../../../common/api/settings/models";
import { VENUE_UNLOCK } from "../../../../common/api/settings/requests";
import BasicModal from "../../../../common/components/BasicModal";
import { handleLogoutClick } from "../../../../common/helpers";
import { useRestApi } from "../../../../common/hooks/useRestApi";
import { ReactComponent as LogoutIcon } from "../../../../common/svg/icons/logout.svg";
import { ReactComponent as ShieldIcon } from "../../../../common/svg/shield.svg";
import { Route } from "../../../../config/router";
import dictionary from "../../../../i18n/en_US/dictionary";
import { actions, store } from "../../../../store";
import Props from "./Props";
import { useStyles } from "./styles";

const PasswordModal: FC<Props> = (props: Props) => {
  const { title, open } = props;
  const classes = useStyles();

  const { dispatch, state } = useContext(store);

  const [password, setPassword] = useState<string>("");

  const [, logout] = useRestApi<boolean, { venueId: number }>(
    Endpoints.LOGOUT,
    "POST"
  );

  const [unlockState, unlock] = useRestApi<
    SettingsResponse,
    { lockPassword: string }
  >(VENUE_UNLOCK(state.venueId as number), "POST");

  const savedPassword = state.settings?.lockPassword || "";

  useEffect(() => {
    if (savedPassword && password.length === savedPassword.length) {
      (async () => {
        await unlock({ lockPassword: password });
      })();
    }
  }, [dispatch, password, savedPassword, state.venueId, unlock]);

  useEffect(() => {
    if (!unlockState.error && !unlockState.loading && unlockState.data) {
      dispatch({ type: actions.SetSettings, payload: unlockState.data });
    }

    if (unlockState.error) {
      setPassword("");
    }
  }, [dispatch, unlockState]);

  useEffect(() => {
    if (!open && password) {
      setPassword("");
    }
  }, [open, password]);

  const history = useHistory();

  const onLogout = async () => {
    dispatch({ type: actions.SetIsLoading, payload: true });
    history.push(Route.Login);
    await logout({ venueId: state.venueId as number });
    handleLogoutClick(dispatch, history)();
    dispatch({ type: actions.SetIsLoading, payload: false });
  };

  const handleEnterPassword = (value: string) => () => {
    setPassword((prevState) => prevState.concat(value));
  };

  const steps = useMemo(
    () =>
      Array.from(savedPassword).map((_, index) => (
        <div
          key={index}
          className={clsx(classes.stepIcon, {
            [classes.stepActive]: index + 1 <= password.length,
          })}
        />
      )),
    [classes.stepActive, classes.stepIcon, password.length, savedPassword]
  );

  const generateButtons = () => {
    const rows = [];

    for (let button = 1; button <= 9; button++) {
      rows.push(
        <Grid
          item
          xs={4}
          className={classes.buttonsRow}
          key={`${button}-button`}>
          <div
            className={classes.button}
            onClick={handleEnterPassword(button.toString())}>
            {button}
          </div>
        </Grid>
      );
    }

    rows.push([
      <Grid item xs={8} className={classes.buttonsRow} key="0-button">
        <div
          className={clsx(classes.button, classes.zeroButton)}
          onClick={handleEnterPassword("0")}>
          0
        </div>
      </Grid>,
      <Grid item xs={4} className={classes.buttonsRow} key="cancel-button">
        <div className={classes.cancelButton} onClick={() => setPassword("")}>
          {dictionary.settings.cancel}
        </div>
      </Grid>,
    ]);

    return rows;
  };

  const buttons = useMemo(generateButtons, [
    classes.button,
    classes.buttonsRow,
    classes.cancelButton,
    classes.zeroButton,
  ]);

  return (
    <BasicModal
      title={title}
      onClose={() => null}
      open={open}
      classes={{ paper: classes.modal }}>
      <LogoutIcon className={classes.logoutIcon} onClick={onLogout} />
      <Grid container className={classes.contentWrapper} spacing={3}>
        <Grid item className={classes.shieldIconWrapper}>
          <ShieldIcon />
        </Grid>
        {state.lockingRequest || unlockState.loading ? (
          <CircularProgress className={classes.loader} />
        ) : (
          <>
            <Grid item className={classes.stepsSection}>
              <div className={classes.stepsWrapper}>{steps}</div>
            </Grid>
            <Grid item>
              <Grid container className={classes.buttonsWrapper}>
                {buttons}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </BasicModal>
  );
};

export default PasswordModal;
