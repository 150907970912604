import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../common/models";
import { colors } from "../../../../../../theme/colors";

export type ClassNames =
  | "root"
  | "icon"
  | "label"
  | "text"
  | "iconContainer"
  | "widgetData"
  | "radio";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        height: 85,
        width: 200,
        position: "relative",
        padding: theme.spacing(3),
        display: "flex",
        cursor: "pointer",
        boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.06)",
        alignItems: "center",
        [theme.breakpoints.down(375)]: {
          height: 60,
        },
      },
      label: {
        fontWeight: "bold",
        color: colors.grey.widgets,
        fontSize: theme.typography.pxToRem(12),
      },
      text: {
        color: colors.grey.primary,
        fontSize: theme.typography.pxToRem(20),
        fontWeight: "bold",
        marginTop: "auto",
      },
      icon: {
        width: 62,
        height: 62,
      },
      iconContainer: {
        minWidth: 48,
        height: 48,
        borderRadius: 6,
        marginRight: theme.spacing(2),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down(375)]: {
          minWidth: 42,
          height: 42,
          marginRight: theme.spacing(1),
        },
      },
      widgetData: {
        display: "flex",
        flexDirection: "column",
        "& > p:first-child": {
          [theme.breakpoints.down(375)]: {
            whiteSpace: "nowrap",
          },
        },
      },
      radio: {
        marginLeft: "auto",
        color: colors.grey.radio,
        [theme.breakpoints.down(470)]: {
          position: "absolute",
          right: "1%",
          top: "35%",
        },
      },
    })
);
