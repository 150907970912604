import { Typography } from "@material-ui/core";
import React, { FC } from "react";

import IconDropdown from "../../../../../../../../../../common/components/IconDropdown";
import { ReactComponent as DeleteIcon } from "../../../../../../../../../../common/svg/icons/close.svg";
import { ReactComponent as EditIcon } from "../../../../../../../../../../common/svg/icons/edit.svg";
import { ReactComponent as ThreeDotsIcon } from "../../../../../../../../../../common/svg/icons/three-dots.svg";
import dictionary from "../../../../../../../../../../i18n/en_US/dictionary";
import Props from "./Props";
import { useStyles } from "./styles";

const ThreeDotsMenu: FC<Props> = (props: Props) => {
  const { onEditClick, onDeleteClick } = props;
  const classes = useStyles();

  return (
    <IconDropdown
      icon={<ThreeDotsIcon />}
      className={classes.threeDots}
      paperClass={classes.paperClass}>
      <div className={classes.menuWrapper}>
        <div className={classes.row} onClick={onEditClick}>
          <EditIcon />
          <span>{dictionary.common.edit}</span>
        </div>
        <div className={classes.row} onClick={onDeleteClick}>
          <DeleteIcon width="21" height="21" />
          <span>{dictionary.common.delete}</span>
        </div>
      </div>
    </IconDropdown>
  );
};

export default ThreeDotsMenu;
