import { CircularProgress, Grid } from "@material-ui/core";
import React, { FC, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  CUSTOMER_CANCEL,
  CUSTOMER_QUEUE_INFO,
} from "../../common/api/customers/requests";
import ButtonPrimary from "../../common/components/ButtonPrimary";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import { useRestApi } from "../../common/hooks/useRestApi";
import { useGlobalStyles } from "../../common/styles";
import dictionary from "../../i18n/en_US/dictionary";
import { QueueData } from "./models";
import Props from "./Props";
import { useStyles } from "./styles";

const QueueInfo: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const { venueId, customerId } = useParams<Record<string, string>>();

  const [queueInfo, loadQueueInfo] = useRestApi<QueueData>(
    CUSTOMER_QUEUE_INFO(venueId, customerId),
    "GET"
  );

  const [cancelOrderRequest, cancelOrder] = useRestApi<QueueData>(
    CUSTOMER_CANCEL(customerId),
    "POST"
  );

  const handleConfirm = async () => {
    await cancelOrder();
    setModalOpen(false);
    loadQueueInfo();
  };

  useEffect(() => {
    loadQueueInfo();
  }, [loadQueueInfo]);

  const { quotedTime, venueName, position, menuUrl } = queueInfo.data || {};

  const isCancelDisabled = typeof quotedTime === "undefined";

  return (
    <div className={classes.contentWrapper}>
      <ConfirmationModal
        ModalProps={{ classes: { paper: classes.cancelModal } }}
        onConfirm={handleConfirm}
        onClose={() => setModalOpen(false)}
        open={modalOpen}
        loading={cancelOrderRequest.loading}
        title="Cancel order"
        text="Are you sure you want to cancel your order?"
        cancelButtonText={dictionary.queueInfo.noButtonText}
        confirmButtonText={dictionary.queueInfo.yesButtonText}
        classes={{
          cancelButton: classes.cancelButton,
        }}
      />
      {queueInfo.loading || !queueInfo.data ? (
        <CircularProgress
          size="4rem"
          color="secondary"
          className={globalClasses.marginAuto}
        />
      ) : (
        <>
          <div className={classes.title}>
            <span>{venueName}</span>
            <span>{dictionary.queueInfo.restaurantWaitlist}</span>
          </div>
          <Grid container className={classes.cards}>
            <div className={classes.infoCard}>
              <span>{position || "-"}</span>
              <span>{dictionary.queueInfo.myPosition}</span>
            </div>
            <div className={classes.infoCard}>
              <span>
                {typeof quotedTime !== "undefined" ? quotedTime : "-"}{" "}
                <span className={classes.minLabel}>
                  {dictionary.queueInfo.minLabel}
                </span>
              </span>
              <span>{dictionary.queueInfo.myEstimatedTime}</span>
            </div>
          </Grid>
          <Grid container className={classes.controls}>
            {menuUrl ? (
              <a href={menuUrl} target="_blank" rel="noreferrer">
                <ButtonPrimary className={classes.menuButton}>
                  {dictionary.queueInfo.menu}
                </ButtonPrimary>
              </a>
            ) : (
              <ButtonPrimary disabled className={classes.menuButton}>
                {dictionary.queueInfo.menu}
              </ButtonPrimary>
            )}
            <ButtonPrimary
              onClick={() => setModalOpen(true)}
              disabled={isCancelDisabled}>
              {dictionary.queueInfo.cancel}
            </ButtonPrimary>
          </Grid>
          <div className={classes.footer}>
            {dictionary.queueInfo.poweredByOciety}
          </div>
        </>
      )}
    </div>
  );
};

export default QueueInfo;
