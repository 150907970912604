import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";

import { useGlobalStyles } from "../../../../../../../../common/styles";
import dictionary from "../../../../../../../../i18n/en_US/dictionary";
import { WidgetTypes } from "../../../../../../models";
import Props from "./Props";
import { useStyles } from "./styles";

const TableWrapper: FC<Props> = (props: Props) => {
  const { children, provided, activeWidget } = props;
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  const isFinished =
    activeWidget === WidgetTypes.Served ||
    activeWidget === WidgetTypes.Cancelled;

  return (
    <Table classes={{ root: classes.tableRoot }}>
      <TableHead>
        <TableRow className={classes.headerRow}>
          <div className={classes.drag}>Drag</div>
          {!isFinished && <div className={classes.numberSymbol}>№</div>}
          <div className={classes.columnsWrapper}>
            <TableCell className={globalClasses.width25}>
              {dictionary.dashboard.name}
            </TableCell>
            <TableCell className={globalClasses.width10}>
              {dictionary.dashboard.quantity}
            </TableCell>
            <TableCell className={globalClasses.width15}>
              {dictionary.dashboard.inQueue}
            </TableCell>
            <TableCell className={globalClasses.width10}>
              {dictionary.dashboard.quoted}
            </TableCell>
            <TableCell
              className={clsx(globalClasses.width15, classes.labelsColumn)}>
              Labels
            </TableCell>
            <TableCell
              className={clsx(globalClasses.width25, classes.actionsColumn)}>
              Actions
            </TableCell>
          </div>
        </TableRow>
      </TableHead>
      <TableBody>
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          className={classes.bodyWrapper}>
          {children}
        </div>
      </TableBody>
    </Table>
  );
};

export default TableWrapper;
