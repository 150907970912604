import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../common/models";
import { colors } from "../../../../../../theme/colors";

export type ClassNames =
  | "container"
  | "customersMobileWrapper"
  | "loader"
  | "cancelButton"
  | "saveButton"
  | "notifyAndComplete"
  | "completeConfirmModal";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      container: {
        overflowY: "auto",
        width: `100%`,
        height: "100%",
        position: "relative",
      },
      customersMobileWrapper: {
        borderTop: `1px solid ${colors.grey.dividerLighter}`,
        width: `calc(100% - ${theme.spacing(6)}px)`,
        margin: theme.spacing(0, 3),
      },
      loader: {
        position: "absolute",
        top: "50%",
        left: "50%",
      },
      cancelButton: {
        border: `2px solid ${colors.grey.border}`,
        backgroundColor: colors.white.primary,
        color: colors.grey.modalText,
        minWidth: 80,
        marginRight: theme.spacing(2),
      },
      saveButton: {
        minWidth: 100,
      },
      notifyAndComplete: {
        marginRight: theme.spacing(2),
      },
      completeConfirmModal: {
        maxWidth: "unset",
      },
    })
);
