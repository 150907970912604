import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../../common/models";
import { colors } from "../../../../../../../../theme/colors";

export type ClassNames =
  | "root"
  | "dragIconWrapper"
  | "nameWrapper"
  | "inQueueRed"
  | "labels"
  | "actionsWrapper"
  | "statusBadge"
  | "itemInfo"
  | "itemInfoHeaders"
  | "itemInfoValues"
  | "actionButton"
  | "deleteIcon"
  | "editIcon"
  | "timesUsed"
  | "name"
  | "tooltip"
  | "renewIcon"
  | "servedItemActionButton";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)",
        alignItems: "center",
        marginTop: theme.spacing(2),
        padding: theme.spacing(3),
        "&:last-child": {
          marginBottom: theme.spacing(2),
        },
      },
      dragIconWrapper: {
        marginLeft: "auto",
      },
      nameWrapper: {
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        "& > span": {
          fontSize: theme.typography.pxToRem(18),
          fontWeight: "bold",
        },
      },
      inQueueRed: {
        color: colors.red.primary,
      },
      labels: {
        fontSize: `${theme.typography.pxToRem(12)} !important`,
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
      },
      actionsWrapper: {
        width: "100%",
        height: 68,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
        borderTop: `1px solid ${colors.grey.dividerLighter}`,
      },
      actionButton: {
        maxWidth: 200,
        width: "22%",
      },
      statusBadge: {
        marginLeft: theme.spacing(2),
      },
      itemInfo: {
        width: "100%",
        fontFamily: theme.typography.fontFamily,
        marginTop: theme.spacing(3),
      },
      itemInfoHeaders: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        marginBottom: theme.spacing(1),
        "& > span": {
          fontSize: theme.typography.pxToRem(12),
          color: colors.grey.secondary,
        },
      },
      itemInfoValues: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        marginBottom: theme.spacing(2),
        "& > span": {
          fontSize: theme.typography.pxToRem(16),
        },
      },
      deleteIcon: {
        "& > *": {
          fill: colors.red.pink,
        },
      },
      editIcon: {
        "& > *": {
          fill: colors.grey.editIconLight,
        },
      },
      timesUsed: {
        fontSize: theme.typography.pxToRem(12),
        color: colors.grey.widgets,
        fontWeight: 500,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(3),
        whiteSpace: "nowrap",
      },
      name: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      },
      tooltip: {
        fontSize: theme.typography.pxToRem(14),
      },
      renewIcon: {
        fontSize: theme.typography.pxToRem(27),
        fill: colors.blue.secondary,
      },
      servedItemActionButton: {
        width: `calc(50% - ${theme.spacing(1)}px)`,
        maxWidth: "unset",
      },
    })
);
