import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

// time format is HH:mm:ss
export const replaceDameInDayJs = (dayjsInstance: Dayjs, time: string) => {
  return dayjs(
    dayjsInstance.toString().replace(/([0-9]+:[0-9]{2})(:[0-9]{2})/, time)
  );
};

// time format is HH:mm:ss
export const getLocalDayjsFromUTCTimeString = (time = "00:00:00") => {
  return replaceDameInDayJs(dayjs.utc(), time);
};

export const getUTCTimeStringFromDate = (date: any) => {
  return dayjs.utc(date).format("HH:mm:ss");
};
