import { ComponentsProps } from "@material-ui/core/styles/props";

import MuiPaper from "./MuiPaper";
import MuiSelect from "./MuiSelect";

const props: ComponentsProps = {
  MuiSelect,
  MuiPaper,
};

export default props;
