import { fade } from "@material-ui/core";
import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../common/models";
import { colors } from "../../../../../../theme/colors";

type ClassNames =
  | "root"
  | "item"
  | "icon"
  | "iconActive"
  | "link"
  | "linkActive"
  | "linkText"
  | "fillPink";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        height: "100%",
      },
      item: {
        display: "flex",
        paddingTop: 0,
        paddingBottom: 0,
      },
      icon: {
        width: 30,
        height: 30,
      },
      iconActive: {
        display: "none",
        width: 30,
        height: 30,
      },
      link: {
        padding: theme.spacing(17 / 8, 17 / 8),
        borderRadius: 4,
        "&.active": {
          backgroundColor: fade(colors.red.pink, 0.15),
          textDecoration: "none",
        },
        "&:hover": {
          textDecoration: "none",
        },
        "&:hover $linkText, &.active $linkText": {
          color: colors.red.pink,
        },
        "&:hover $icon, &.active $icon": {
          display: "none",
        },
        "&:hover $iconActive, &.active $iconActive": {
          display: "flex",
        },
      },
      linkText: {
        color: colors.grey.linkIcon,
        whiteSpace: "nowrap",
        marginLeft: theme.spacing(30 / 8),
        fontWeight: "bold",
        fontSize: theme.typography.pxToRem(16),
      },
      linkActive: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
        "& > $iconActive": {
          display: "flex",
        },
        "& > $icon": {
          display: "none",
        },
      },
      fillPink: {
        "& path": {
          fill: colors.red.pink,
        },
      },
    })
);
