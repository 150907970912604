import { AxiosError } from "axios";
import dayjs from "dayjs";
import React, { Dispatch } from "react";

import { Route } from "../config/router";
import Actions from "../store/actions";
import { Action } from "../store/models";
import { QueueItem } from "../views/Dashboard/models";
import Endpoints from "./api/endpoints";
import { excludedRefreshRoutes, mfaTokenKey } from "./constants";

export const reorderArray = <T>(
  list: T[],
  startIndex: number,
  endIndex: number
) => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export function fieldsOrDefaults<Fields extends Record<string, any>, BasicType>(
  fields: Fields,
  defaults: Record<string, BasicType>
) {
  const allFields: string[] = Object.keys(defaults);
  return allFields.reduce(
    (acc, field) => ({ ...acc, [field]: fields[field] || defaults[field] }),
    {}
  );
}

export const handleLogoutClick =
  (dispatch: React.Dispatch<Action>, history: any) => () => {
    localStorage.removeItem(mfaTokenKey);

    dispatch({ type: Actions.SetAuthTokens, payload: null });
    dispatch({ type: Actions.SetMfaToken, payload: null });
    dispatch({ type: Actions.SetSettings, payload: null });
    dispatch({ type: Actions.SetCustomers, payload: [] });

    history.push(Route.Login);
  };

export const excludedRefresh = (error: AxiosError) => {
  return excludedRefreshRoutes.includes(error.config.url as Endpoints);
};

export const replaceParams = (
  url: string,
  replacements: Record<string, any>
) => {
  const paramNames = Object.keys(replacements);
  let _newUrl = url;
  paramNames.forEach((param) => {
    _newUrl = _newUrl.replace(`:${param}`, replacements[param]);
  });

  return _newUrl;
};

export const hexToRgb = (hex: string) =>
  // @ts-ignore
  hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m: string, r: string, g: string, b: string) =>
        "#" + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g)
    .map((x: string) => parseInt(x, 16));

const formatPhoneNumbers = (customers: QueueItem[]) => {
  return customers.map((customer: QueueItem) => ({
    ...customer,
  }));
};

const getWaitTimes = (customers: QueueItem[] = []) =>
  customers.reduce(
    (acc, { id, queuedAt, stoppedAt }) => ({
      ...acc,
      [id]: dayjs(stoppedAt || new Date()).diff(queuedAt, "minutes"),
    }),
    {}
  );

export const setCustomers = (
  data: QueueItem[] = [],
  dispatch: Dispatch<Action>
) => {
  const formattedCustomers = formatPhoneNumbers(data || []);
  const waitTimes = getWaitTimes(data);

  dispatch({ type: Actions.SetCustomers, payload: formattedCustomers });
  dispatch({
    type: Actions.SetWaitingTimes,
    payload: waitTimes,
    meta: { replace: true },
  });
};
