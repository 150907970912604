import { Dispatch, SetStateAction, useState } from "react";

function useInputs<F extends string, T>(
  initialValues: Record<F, T>
): [
  Record<F, T>,
  (key: string, value: T) => void,
  () => void,
  Dispatch<SetStateAction<Record<F, T>>>
] {
  const [values, setValues] = useState<Record<F, T>>(initialValues);

  const setValue = (key: string, value: T) =>
    setValues((prevValues) => ({ ...prevValues, [key]: value }));

  const reset: () => void = () => setValues(initialValues);

  return [values, setValue, reset, setValues];
}

export default useInputs;
