import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import clsx from "clsx";
import React, { FC, useContext, useEffect, useState } from "react";

import { OcietyTheme } from "../../common/models";
import { store } from "../../store";
import Sidebar from "./components/Sidebar";
import Props from "./Props";
import { useStyles } from "./styles";

const Main: FC<Props> = (props: Props) => {
  const { children, className } = props;

  const classes = useStyles();
  const theme: OcietyTheme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const { state } = useContext(store);

  const [openSidebar, setOpenSidebar] = useState(isDesktop);

  useEffect(() => {
    setOpenSidebar(isDesktop);
  }, [isDesktop]);

  const toggleSidebar = () => {
    setOpenSidebar((prevState) => !prevState);
  };

  return (
    <div
      className={clsx(classes.root, className, {
        [classes.blurry]: !!state.settings?.isLocked || state.lockingRequest,
      })}>
      <Sidebar
        variant={isDesktop ? "permanent" : "temporary"}
        open={openSidebar}
        toggleSidebar={toggleSidebar}
      />
      <div className={isDesktop ? classes.noSidebar : classes.mobile}>
        <div className={classes.commonWrapper}>
          <div className={classes.container}>
            <main className={classes.content}>
              {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                  return React.cloneElement(child, {
                    toggleSidebar,
                    openSidebar,
                  });
                }
                return child;
              })}
            </main>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
