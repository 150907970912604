import { Paper, Typography } from "@material-ui/core";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import clsx from "clsx";
import React, { FC, ReactNode, useMemo } from "react";

import OcietyTooltip from "../../../../../../../../common/components/OcietyTooltip";
import { useGlobalStyles } from "../../../../../../../../common/styles";
import { ReactComponent as BellIcon } from "../../../../../../../../common/svg/icons/bell-normal.svg";
import { ReactComponent as BellNotifiedIcon } from "../../../../../../../../common/svg/icons/bell-notified.svg";
import { ReactComponent as CloseIcon } from "../../../../../../../../common/svg/icons/close.svg";
import { ReactComponent as DoneIcon } from "../../../../../../../../common/svg/icons/done.svg";
import { ReactComponent as DragIcon } from "../../../../../../../../common/svg/icons/drag.svg";
import { ReactComponent as EditIcon } from "../../../../../../../../common/svg/icons/edit.svg";
import dictionary from "../../../../../../../../i18n/en_US/dictionary";
import { colors } from "../../../../../../../../theme/colors";
import { QueueItemStatuses } from "../../../../../../models";
import ActionContainer from "../ActionContainer";
import useQueueItem from "../QueueItem/useQueueItem";
import StatusBadge from "../StatusBadge";
import Props from "./Props";
import { useStyles } from "./styles";

const QueueItemMobile: FC<Props> = (props: Props) => {
  const {
    item,
    provided,
    handleCustomerEdit,
    handleCustomerDelete,
    handleCustomerComplete,
    completing,
    notifying,
    handleCustomerNotify,
    handleCustomerRenew,
    renewing,
  } = props;
  const {
    name,
    quantity,
    quotedTime,
    labels,
    status,
    isNotified,
    notifiedAt,
    timesUsed,
  } = item;

  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  const { helpers, values } = useQueueItem(props);

  const actions: ReactNode = useMemo<ReactNode>(() => {
    if (values.isFinished) {
      return (
        <>
          {values.isServed && (
            <OcietyTooltip title={dictionary.dashboard.moveToWaiting}>
              <ActionContainer
                className={clsx(
                  classes.actionButton,
                  classes.servedItemActionButton
                )}
                icon={<RotateLeftIcon className={classes.renewIcon} />}
                color={colors.blue.secondary}
                onClick={() => handleCustomerRenew(item.id)}
                squared
                transparent
                loading={renewing}
              />
            </OcietyTooltip>
          )}
          <OcietyTooltip title={dictionary.dashboard.removeFromList}>
            <ActionContainer
              icon={<CloseIcon className={classes.deleteIcon} />}
              color={colors.red.pink}
              onClick={() => handleCustomerDelete(item.id)}
              className={clsx(classes.actionButton, {
                [classes.servedItemActionButton]: values.isServed,
              })}
              squared
              transparent
              fluid={values.isCancelled}
            />
          </OcietyTooltip>
        </>
      );
    } else {
      return (
        <>
          <OcietyTooltip title={dictionary.dashboard.removeFromList}>
            <ActionContainer
              icon={<CloseIcon className={classes.deleteIcon} />}
              color={colors.red.pink}
              onClick={() => handleCustomerDelete(item.id)}
              className={classes.actionButton}
              squared
              transparent
            />
          </OcietyTooltip>
          <OcietyTooltip title={dictionary.dashboard.editCustomer}>
            <ActionContainer
              icon={<EditIcon className={classes.editIcon} />}
              color={colors.grey.radio}
              onClick={() => handleCustomerEdit(item)}
              className={classes.actionButton}
              squared
              transparent
            />
          </OcietyTooltip>

          {isNotified ? (
            <ActionContainer
              icon={<BellNotifiedIcon />}
              color={colors.blue.violet}
              className={classes.actionButton}
              squared
            />
          ) : (
            <OcietyTooltip title={dictionary.dashboard.notifyButtonText}>
              <ActionContainer
                icon={<BellIcon />}
                color={colors.orange.light}
                className={classes.actionButton}
                onClick={() => handleCustomerNotify(item.id)}
                loading={notifying}
                squared
              />
            </OcietyTooltip>
          )}
          <OcietyTooltip title={dictionary.dashboard.moveToServed}>
            <ActionContainer
              icon={<DoneIcon />}
              color={colors.green.lighter}
              className={classes.actionButton}
              squared
              loading={completing}
              onClick={() => handleCustomerComplete(item)}
            />
          </OcietyTooltip>
        </>
      );
    }
  }, [
    renewing,
    values.isFinished,
    values.isServed,
    values.isCancelled,
    classes.actionButton,
    classes.servedItemActionButton,
    classes.renewIcon,
    classes.deleteIcon,
    classes.editIcon,
    handleCustomerRenew,
    item,
    handleCustomerDelete,
    isNotified,
    notifying,
    completing,
    handleCustomerEdit,
    handleCustomerNotify,
    handleCustomerComplete,
  ]);

  const parsedLabels = helpers.parseLabels(labels).join(", ");

  return (
    <Paper
      className={classes.root}
      {...provided.draggableProps}
      ref={provided.innerRef}
      {...provided.dragHandleProps}>
      <div className={classes.nameWrapper}>
        <OcietyTooltip title={name}>
          <Typography component="span" className={classes.name}>
            {name}
          </Typography>
        </OcietyTooltip>
        <StatusBadge
          status={status}
          notified={isNotified}
          notifiedAt={notifiedAt}
          className={classes.statusBadge}
        />
        {timesUsed > 1 && (
          <Typography component="small" className={classes.timesUsed}>
            {dictionary.dashboard.beenHere.replace(
              "{times}",
              timesUsed.toString(10)
            )}
          </Typography>
        )}
        <div className={classes.dragIconWrapper}>
          <DragIcon />
        </div>
      </div>
      <div className={classes.itemInfo}>
        <div className={classes.itemInfoHeaders}>
          <Typography component="span" className={globalClasses.width25}>
            {dictionary.dashboard.quantity}
          </Typography>
          <Typography component="span" className={globalClasses.width25}>
            {dictionary.dashboard.inQueue}
          </Typography>
          <Typography component="span" className={globalClasses.width25}>
            {dictionary.dashboard.quoted}
          </Typography>
          <Typography component="span" className={globalClasses.width25}>
            {dictionary.dashboard.labels}
          </Typography>
        </div>
        <div className={classes.itemInfoValues}>
          <Typography component="span" className={globalClasses.width25}>
            {quantity}
          </Typography>
          <Typography
            component="span"
            className={clsx(globalClasses.width25, {
              [classes.inQueueRed]: values.isOvertime,
            })}>
            {values.minutesWaiting} {dictionary.dashboard.minLabel}
          </Typography>
          <Typography component="span" className={globalClasses.width25}>
            {quotedTime} {dictionary.dashboard.minLabel}
          </Typography>
          <OcietyTooltip title={parsedLabels}>
            <Typography
              component="span"
              className={clsx(globalClasses.width25, classes.labels)}>
              {parsedLabels}
            </Typography>
          </OcietyTooltip>
        </div>
      </div>

      <div className={classes.actionsWrapper}>{actions}</div>
    </Paper>
  );
};

export default QueueItemMobile;
