export enum Route {
  Home = "",
  Login = "login",
  Dashboard = "dashboard",
  Settings = "settings",
  About = "about",
  Waitlist = ":venueId/waitlist/:customerId",
}

export const getRouteWithSlash = (route: Route) => "/" + route;
