import { Checkbox, FormControlLabel, Grid, TextField } from "@material-ui/core";
import clsx from "clsx";
import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { CustomerRequest } from "../../../../common/api/customers/models";
import { CUSTOMER, CUSTOMERS } from "../../../../common/api/customers/requests";
import BasicModal from "../../../../common/components/BasicModal";
import ButtonPrimary from "../../../../common/components/ButtonPrimary";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import PhoneNumberInput from "../../../../common/components/PhoneNumberInput";
import { fieldsOrDefaults } from "../../../../common/helpers";
import { useForm } from "../../../../common/hooks/useForm";
import useInputs from "../../../../common/hooks/useInputs";
import { useRestApi } from "../../../../common/hooks/useRestApi";
import dictionary from "../../../../i18n/en_US/dictionary";
import { store } from "../../../../store";
import { colors } from "../../../../theme/colors";
import { QueueItem, QueueItemLabel } from "../../models";
import {
  BooleanFields,
  booleanFields,
  FormValues,
  GuestForm,
  GuestFormBooleanFields,
  GuestFormField,
  GuestFormNumberFields,
  NumberFields,
  numberFields,
  schema,
} from "./form";
import Props from "./Props";
import { useStyles } from "./styles";

type DefaultValues = {
  initString: FormValues;
  initBoolean: BooleanFields;
  initNumber: NumberFields;
};

type Customer = QueueItem | null | undefined;

const GuestModal = (props: Props) => {
  const { open, onClose, customer, handleLoadCustomers } = props;
  const classes = useStyles();
  const { state } = useContext(store);
  const [isOpenWarningPopup, setIsOpenWarningPopup] = useState(false);
  const [wrongFormatError, setWrongFormatError] = useState(false);

  const handleCloseWarningPopup = () => {
    setIsOpenWarningPopup(false);
  };

  const duplicateWaitingPhoneNumbers = (customerPhone: string) => {
    const rawPhone = customerPhone.substring(2);
    const phoneDuplicate = state.customers.filter(
      (customer) => customer.status === "waiting" && customer.phone === rawPhone
    );

    if (phoneDuplicate.length) {
      setIsOpenWarningPopup(true);
    }
  };

  const getDefaultValues: (customer: Customer) => DefaultValues = (
    customer: Customer
  ) => {
    if (!customer) {
      return {
        initString: GuestForm.values,
        initBoolean: booleanFields,
        initNumber: numberFields,
      };
    }

    const formValues = fieldsOrDefaults<QueueItem, string>(
      customer,
      GuestForm.values
    );

    const customerLabels = customer.labels.reduce(
      (acc: Record<string, boolean>, label: string) => ({
        ...acc,
        [label]: true,
      }),
      {}
    );

    const initBoolean = fieldsOrDefaults<Record<string, boolean>, boolean>(
      customerLabels,
      booleanFields
    );
    const initNumber = fieldsOrDefaults<QueueItem, number>(
      customer,
      numberFields
    );

    return {
      initString: formValues,
      initBoolean,
      initNumber,
    } as DefaultValues;
  };

  const { initString, initBoolean, initNumber } = useMemo(
    () => getDefaultValues(customer),
    [customer]
  );

  const { form, setAndValidate, setFormValues, validateForm } = useForm(
    schema,
    GuestForm
  );

  const [checkboxes, setCheckboxValue, resetCheckboxes, setCheckboxes] =
    useInputs<GuestFormBooleanFields, boolean>(initBoolean as BooleanFields);

  const [numberInputs, setNumberInputValue, resetInputs, setInputs] = useInputs<
    GuestFormNumberFields,
    number
  >(initNumber as NumberFields);

  useEffect(() => {
    setWrongFormatError(false);
  }, [form.values.phone]);

  useEffect(() => {
    setCheckboxes(initBoolean as BooleanFields);
    setInputs(initNumber as NumberFields);
    setFormValues(initString as FormValues);
  }, [
    customer,
    initBoolean,
    initNumber,
    initString,
    setCheckboxes,
    setFormValues,
    setInputs,
  ]);

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name: key, value } = event.target;
    setAndValidate(key as GuestFormField, value);
  };

  const getRawPhoneNumber = (phoneNumber: string) =>
    `${phoneNumber.replace(/\(|\)|_|-|\s/g, "")}`;

  const handlePhoneChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    handleChange(event);
    const value = event.target.value;
    duplicateWaitingPhoneNumbers(getRawPhoneNumber(value));
  };

  const handleCheckboxClick = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name } = event.target;
    // @ts-ignore
    const prevValue: boolean | undefined = checkboxes[name];
    setCheckboxValue(name, !prevValue);
  };

  const handleNumberInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    condFunc?: (parsedValue: number) => boolean
  ) => {
    const { name, value } = event.target;
    const parsedValue = parseInt(value, 10);

    if (condFunc) {
      if (condFunc(parsedValue)) {
        setNumberInputValue(name, parsedValue);
      }
    } else {
      setNumberInputValue(name, parsedValue);
    }
  };

  const resetForm = () => {
    resetCheckboxes();
    resetInputs();
    // @ts-ignore
    setFormValues(GuestForm.values);
  };

  const handleModalClose = () => {
    onClose();
    resetForm();
  };

  const getActiveLabels = () =>
    Object.entries(checkboxes)
      .filter(([, active]) => active)
      .map(([label]) => label) as QueueItemLabel[];

  const [{ loading: creatingCustomer }, createCustomer] = useRestApi<
    QueueItem,
    CustomerRequest
  >(CUSTOMERS(state.venueId as number), "POST");

  const [{ loading: updatingCustomer }, updateCustomer] = useRestApi<
    QueueItem,
    CustomerRequest
  >(CUSTOMER(state.venueId as number, customer?.id as number), "PUT");

  const handleSaveClick = async () => {
    const labels = getActiveLabels();
    const customerData = {
      ...numberInputs,
      ...form.values,
      labels,
    };

    customerData.name = customerData.name.trim();

    if (validateForm()) {
      if (customer) {
        customerData.phone = getRawPhoneNumber(customerData.phone);
        await updateCustomer(customerData);
        handleLoadCustomers();
        handleModalClose();
      } else {
        customerData.phone = getRawPhoneNumber(customerData.phone);
        await createCustomer(customerData).then((res) => {
          if (res) {
            setWrongFormatError(false);
            handleLoadCustomers();
            handleModalClose();
          } else setWrongFormatError(true);
        });
      }
    }
  };
  const clearFieldWhenZero = (e: any) => {
    if (e.target.value === "0") {
      e.target.value = "";
    }
  };

  return (
    <BasicModal
      open={open}
      closeButton
      title={
        !customer
          ? dictionary.guestModal.addGuest
          : dictionary.guestModal.editGuest
      }
      onClose={handleModalClose}
      // @ts-ignore
      classes={{ paper: classes.modalPaper }}
      disableBackdropClick>
      <Grid container spacing={3}>
        <Grid item sm={6} xs={12} className={classes.modalItem}>
          <label htmlFor="phone" className={classes.inputLabel}>
            {dictionary.guestModal.phoneNumber}
          </label>
          <ConfirmationModal
            open={isOpenWarningPopup}
            onClose={handleCloseWarningPopup}
            text={dictionary.guestModal.duplicatePhoneNumbersPopup}>
            <ButtonPrimary onClick={handleCloseWarningPopup}>
              {dictionary.guestModal.continue}
            </ButtonPrimary>
          </ConfirmationModal>
          <PhoneNumberInput
            value={form.values.phone}
            onChange={handlePhoneChange}
            error={form.errors.phone}
            isWrongFormat={wrongFormatError}
          />
        </Grid>
        <Grid item sm={6} xs={12} className={classes.modalItem}>
          <label htmlFor="name" className={classes.inputLabel}>
            {dictionary.guestModal.name}
          </label>
          <TextField
            fullWidth
            name="name"
            variant="outlined"
            type="text"
            onChange={handleChange}
            value={form.values.name}
            error={!!form.errors.name}
            helperText={form.errors.name}
            classes={{ root: classes.modalItemInput }}
            required
          />
        </Grid>
        <Grid item sm={6} xs={12} className={classes.modalItem}>
          <label htmlFor="quantity" className={classes.inputLabel}>
            {dictionary.guestModal.quantityInParty}
          </label>
          <TextField
            fullWidth
            name="quantity"
            variant="outlined"
            type="number"
            InputProps={{ inputProps: { min: 1 } }}
            onChange={handleNumberInputChange}
            value={numberInputs.quantity}
          />
        </Grid>
        <Grid item sm={6} xs={12} className={classes.modalItem}>
          <label htmlFor="children" className={classes.inputLabel}>
            {dictionary.guestModal.howManyChildren}
          </label>
          <TextField
            fullWidth
            name="childrenAmount"
            type="number"
            variant="outlined"
            InputProps={{ inputProps: { min: 0 } }}
            onClick={clearFieldWhenZero}
            onChange={handleNumberInputChange}
            value={numberInputs.childrenAmount}
          />
        </Grid>
        <Grid item sm={6} xs={12} className={classes.modalItem}>
          <label htmlFor="quotedTime" className={classes.inputLabel}>
            {dictionary.guestModal.quotedTime}
          </label>
          <TextField
            fullWidth
            name="quotedTime"
            type="number"
            variant="outlined"
            InputProps={{ inputProps: { min: 1 } }}
            onChange={handleNumberInputChange}
            value={numberInputs.quotedTime}
            classes={{ root: classes.modalItemInput }}
          />
        </Grid>
      </Grid>
      <div className={clsx(classes.modalItem, classes.labelsWrapper)}>
        <label htmlFor="labels" className={classes.inputLabel}>
          {dictionary.guestModal.labels}
        </label>
        <Grid container id="labels" spacing={2}>
          <Grid item sm={4} xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={handleCheckboxClick}
                  checked={checkboxes.birthday}
                  name="birthday"
                />
              }
              label={dictionary.guestModal.birthday}
              classes={{ label: classes.checkboxLabel }}
            />
          </Grid>
          <Grid item sm={4} xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={handleCheckboxClick}
                  checked={checkboxes.vip}
                  name="vip"
                />
              }
              label={dictionary.guestModal.vip}
              classes={{ label: classes.checkboxLabel }}
            />
          </Grid>
          <Grid item sm={4} xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={handleCheckboxClick}
                  checked={checkboxes.hasCoupon}
                  name="hasCoupon"
                />
              }
              label={dictionary.guestModal.hasCoupon}
              classes={{ label: classes.checkboxLabel }}
            />
          </Grid>
          <Grid item sm={4} xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={handleCheckboxClick}
                  checked={checkboxes.anniversary}
                  name="anniversary"
                />
              }
              label={dictionary.guestModal.anniversary}
              classes={{ label: classes.checkboxLabel }}
            />
          </Grid>
          <Grid item sm={4} xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={handleCheckboxClick}
                  checked={checkboxes.friendOfOwner}
                  name="friendOfOwner"
                />
              }
              label={dictionary.guestModal.friendOfOwner}
              classes={{ label: classes.checkboxLabel }}
            />
          </Grid>
          <Grid item sm={4} xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={handleCheckboxClick}
                  checked={checkboxes.hasAllergies}
                  name="hasAllergies"
                />
              }
              label={dictionary.guestModal.hasAllergies}
              classes={{ label: classes.checkboxLabel }}
            />
          </Grid>
          <Grid item sm={4} xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={handleCheckboxClick}
                  checked={checkboxes.over21}
                  name="over21"
                />
              }
              label={dictionary.guestModal.over21}
              classes={{ label: classes.checkboxLabel }}
            />
          </Grid>
          <Grid item sm={4} xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={handleCheckboxClick}
                  checked={checkboxes.vegan}
                  name="vegan"
                />
              }
              label={dictionary.guestModal.vegan}
              classes={{ label: classes.checkboxLabel }}
            />
          </Grid>
        </Grid>
      </div>
      <Grid container className={classes.controlsWrapper}>
        <Grid item sm="auto" xs={12}>
          <ButtonPrimary
            className={clsx(classes.controls, classes.cancelButton)}
            onClick={handleModalClose}
            style={{ backgroundColor: colors.white.primary }}>
            {dictionary.guestModal.cancel}
          </ButtonPrimary>
        </Grid>
        <Grid item sm="auto" xs={12}>
          <ButtonPrimary
            loading={creatingCustomer || updatingCustomer}
            className={clsx(classes.controls, classes.saveButton)}
            onClick={handleSaveClick}
            disabled={!form.isValid || wrongFormatError}
            style={{ backgroundColor: colors.green.secondary }}>
            {dictionary.guestModal.save}
          </ButtonPrimary>
        </Grid>
      </Grid>
    </BasicModal>
  );
};

export default GuestModal;
