import { Paper, Radio, SvgIcon, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";

import Props from "./Props";
import { useStyles } from "./styles";

const DashboardWidgetSmall: FC<Props> = (props: Props) => {
  const {
    color,
    icon,
    label,
    text,
    type,
    activeWidget,
    withoutRadio,
    iconViewBox,
    onClick,
  } = props;
  const classes = useStyles();

  const active: boolean = type === activeWidget;
  const _onClick = onClick ? () => onClick(type) : () => null;

  const Icon = icon;

  return (
    <Paper
      className={clsx(classes.root)}
      onClick={_onClick}
      style={active ? { border: `1px solid ${color}` } : {}}>
      <div className={classes.iconContainer} style={{ backgroundColor: color }}>
        <SvgIcon viewBox={iconViewBox}>
          <Icon />
        </SvgIcon>
      </div>
      <div className={classes.widgetData}>
        <Typography className={classes.label}>{label}</Typography>
        <Typography className={classes.text}>{text}</Typography>
      </div>
      {!withoutRadio && (
        <Radio
          checked={active}
          className={classes.radio}
          style={active ? { color } : {}}
        />
      )}
    </Paper>
  );
};

export default DashboardWidgetSmall;
