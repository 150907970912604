import { List, useMediaQuery, useTheme } from "@material-ui/core";
import { SvgIcon } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";

import OcietyNavLink from "../../../../../../common/components/OcietyNavLink";
import { OcietyTheme } from "../../../../../../common/models";
import { sortByOrder } from "../../../../../../common/utils/objectArrayUtils";
import { Page } from "../../pages";
import Props from "./Props";
import { useStyles } from "./styles";

const SidebarNav: FC<Props> = (props: Props) => {
  const { className, pages, isSidebarOpen, toggleSidebar, ...rest } = props;
  const classes = useStyles();

  const theme: OcietyTheme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleLinkClick = () => {
    if (isSidebarOpen && isMobile) {
      toggleSidebar();
    }
  };

  const renderLink = ({
    route,
    icon,
    iconActive,
    label,
    onClick,
    iconStyles,
    labelClassName,
    disabled,
  }: Page) => {
    const Icon: any = icon;
    const ActiveIcon: any = iconActive;

    const onNavLinkClick = () => {
      handleLinkClick();
      if (onClick) onClick();
    };

    return (
      <OcietyNavLink
        to={route}
        key={route || label}
        className={classes.link}
        onClick={onNavLinkClick}
        linkTextClassName={clsx(classes.linkText, labelClassName)}
        disabled={disabled}
        icon={
          <>
            <SvgIcon
              className={classes.icon}
              viewBox="0 0 30 30"
              style={iconStyles}>
              <Icon />
            </SvgIcon>
            <SvgIcon
              className={classes.iconActive}
              viewBox="0 0 30 30"
              style={iconStyles}>
              <ActiveIcon className={classes.fillPink} />
            </SvgIcon>
          </>
        }>
        {label}
      </OcietyNavLink>
    );
  };

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      {pages.sort(sortByOrder).map(renderLink)}
    </List>
  );
};

export default SidebarNav;
