interface EntityWithOrder {
  order?: number;
}

export function sortByOrder<T extends EntityWithOrder>(a: T, b: T): number {
  if (!a.order && !b.order) {
    return 0;
  } else if (!a.order) {
    return 1;
  } else if (!b.order) {
    return -1;
  } else {
    return a.order - b.order;
  }
}

export function arraySum(arr: number[]) {
  return arr.reduce((acc, curr) => acc + curr, 0);
}

export function arrayAvg(arr: number[]) {
  return arraySum(arr) / arr.length;
}
