import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { colors } from "../../../theme/colors";
import { OcietyTheme } from "../../models";

export type ClassNames =
  | "name"
  | "titleSection"
  | "searchBar"
  | "nameWrapper"
  | "topNavigation"
  | "topNavigationControls"
  | "topMargin"
  | "fullWidth"
  | "addButton"
  | "searchButton"
  | "fullWidthControls";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      name: {
        fontSize: theme.typography.pxToRem(32),
        fontFamily: theme.typography.fontFamily,
        color: colors.grey.primary,
        fontWeight: 700,
        [theme.breakpoints.down(375)]: {
          fontSize: theme.typography.pxToRem(24),
        },
      },
      titleSection: {
        width: `calc(100% - ${theme.spacing(4)}px)`,
        margin: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column",
        },
      },
      fullWidth: {
        width: "100% !important",
      },
      topMargin: {
        "& > div:last-child": {
          [theme.breakpoints.down("xs")]: {
            marginTop: 20,
            width: "100%",
          },
          [theme.breakpoints.down("sm")]: {
            marginTop: 20,
          },
        },
      },
      searchBar: {
        fontSize: theme.typography.pxToRem(17),
        fontWeight: 600,
      },
      nameWrapper: {
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
      },
      topNavigation: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& > svg": {
          width: 24,
          height: 24,
        },
      },
      addButton: {
        width: 200,
        marginLeft: theme.spacing(2),
        "& > svg": {
          width: 16,
          height: 16,
        },
        height: 50,
        [theme.breakpoints.down("sm")]: {
          height: 44,
        },
      },
      topNavigationControls: {
        display: "flex",
        alignItems: "center",
      },
      searchButton: {
        width: 48,
        height: 44,
        [theme.breakpoints.up("md")]: {
          height: 50,
          width: 56,
        },
        backgroundColor: colors.grey.elementsSecondary,
        border: `1px solid ${colors.grey.radio}`,
        borderRadius: 4,
        display: "flex",
        "& > svg": {
          margin: "auto",
          width: 20,
          height: 20,
        },
        cursor: "pointer",
      },
      fullWidthControls: {
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
      },
    })
);
