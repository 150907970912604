import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { colors } from "../../../theme/colors";
import { OcietyTheme } from "../../models";

export type ClassNames = "button" | "buttonLarge" | "loader";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      button: {
        fontWeight: "bold",
        textTransform: "none",
        fontSize: theme.typography.pxToRem(16),
        lineHeight: theme.typography.pxToRem(16),
        minHeight: 44,
        minWidth: 132,
        boxShadow: "none",
        borderRadius: 6,
      },
      buttonLarge: {
        minHeight: 50,
      },
      loader: {
        color: colors.white.primary,
        marginLeft: theme.spacing(1),
      },
    })
);
