import React, { FC, useCallback, useContext, useEffect, useState } from "react";

import TitleSection from "../../../../common/components/TitleSection";
import { store } from "../../../../store";
import { QueueItem, WidgetTypes } from "../../models";
import QueueTable from "./components/QueueTable";
import WidgetsPanel from "./components/WidgetsPanel";
import Props from "./Props";
import { useStyles } from "./styles";

const DashboardData: FC<Props> = (props: Props) => {
  const {
    data: queue,
    toggleSidebar,
    setEditingCustomer,
    setModalOpen,
    loading,
  } = props;
  const { state } = useContext(store);
  const classes = useStyles();

  const [activeWidget, setActiveWidget] = useState<WidgetTypes>(
    WidgetTypes.Waiting
  );
  const [customerList, setCustomerList] = useState(queue);

  useEffect(() => {
    setCustomerList(state.customers);
  }, [state.customers]);

  const handleWidgetClick = (type: WidgetTypes) => setActiveWidget(type);

  const handleCustomerEdit = (customer: QueueItem) => {
    setEditingCustomer(customer);
    setModalOpen(true);
  };

  const handleCustomerSearch = useCallback(
    (query: string) => {
      if (query) {
        setCustomerList(() => {
          const lowerQuery = query.toLowerCase();
          return state.customers.filter(
            ({ name, phone }) =>
              name.toLowerCase().includes(lowerQuery) ||
              `+1${phone}`.includes(lowerQuery)
          );
        });
      } else {
        setCustomerList(state.customers);
      }
    },
    [state.customers]
  );

  return (
    <div className={classes.contentWrapper}>
      <TitleSection
        name={state.settings?.venue?.name}
        toggleSidebar={toggleSidebar}
        withSearchbar
        className={classes.topNav}
        setModalOpen={setModalOpen}
        onSearchChange={handleCustomerSearch}
        withAddButton
      />
      <WidgetsPanel
        customers={customerList}
        activeWidget={activeWidget}
        handleWidgetClick={handleWidgetClick}
      />
      <QueueTable
        loading={loading}
        customers={customerList}
        activeWidget={activeWidget}
        handleCustomerEdit={handleCustomerEdit}
      />
    </div>
  );
};

export default DashboardData;
