import { SvgIcon } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";

import { ReactComponent as CaretDown } from "../../svg/icons/caret-down.svg";
import Props from "./Props";
import { useStyles } from "./styles";

const SelectIcon: FC<Props> = (props: Props) => {
  const { iconWrapperClassName, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, iconWrapperClassName)}>
      <SvgIcon viewBox={"-6 -6 24 24"} {...rest}>
        <CaretDown />
      </SvgIcon>
    </div>
  );
};

export default SelectIcon;
