import { Paper, TableCell, Typography } from "@material-ui/core";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import clsx from "clsx";
import React, { FC, ReactNode, useMemo } from "react";

import OcietyTooltip from "../../../../../../../../common/components/OcietyTooltip";
import { useGlobalStyles } from "../../../../../../../../common/styles";
import { ReactComponent as BellIcon } from "../../../../../../../../common/svg/icons/bell-normal.svg";
import { ReactComponent as BellNotifiedIcon } from "../../../../../../../../common/svg/icons/bell-notified.svg";
import { ReactComponent as ClockIcon } from "../../../../../../../../common/svg/icons/clock.svg";
import { ReactComponent as CloseIcon } from "../../../../../../../../common/svg/icons/close.svg";
import { ReactComponent as DoneIcon } from "../../../../../../../../common/svg/icons/done.svg";
import { ReactComponent as DragIcon } from "../../../../../../../../common/svg/icons/drag.svg";
import { ReactComponent as SandclockIcon } from "../../../../../../../../common/svg/icons/sandclock.svg";
import { ReactComponent as ServedIcon } from "../../../../../../../../common/svg/icons/served.svg";
import dictionary from "../../../../../../../../i18n/en_US/dictionary";
import { colors } from "../../../../../../../../theme/colors";
import ActionContainer from "../ActionContainer";
import StatusBadge from "../StatusBadge";
import ThreeDotsMenu from "./components/ThreeDotsMenu";
import Props from "./Props";
import { useStyles } from "./styles";
import useQueueItem from "./useQueueItem";

const QueueItem: FC<Props> = (props: Props) => {
  const {
    item,
    index,
    provided,
    handleCustomerEdit,
    handleCustomerDelete,
    completing,
    handleCustomerComplete,
    notifying,
    handleCustomerNotify,
    handleCustomerRenew,
    renewing,
  } = props;
  const {
    name,
    quantity,
    quotedTime,
    labels,
    status,
    isNotified,
    notifiedAt,
    timesUsed,
  } = item;

  const { values, helpers } = useQueueItem(props);

  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  const actions: ReactNode = useMemo<ReactNode>(() => {
    if (values.isFinished) {
      return (
        <>
          {values.isServed && (
            <OcietyTooltip title={dictionary.dashboard.moveToWaiting}>
              <ActionContainer
                loading={renewing}
                className={classes.wideAction}
                icon={<RotateLeftIcon className={classes.renewIcon} />}
                color={colors.blue.secondary}
                onClick={() => handleCustomerRenew(item.id)}
                squared
              />
            </OcietyTooltip>
          )}
          <OcietyTooltip title={dictionary.dashboard.removeFromList}>
            <ActionContainer
              className={classes.wideAction}
              icon={<CloseIcon />}
              color={colors.red.pink}
              onClick={() => handleCustomerDelete(item.id)}
              squared
            />
          </OcietyTooltip>
        </>
      );
    } else {
      return (
        <>
          {isNotified ? (
            <ActionContainer
              className={classes.wideAction}
              icon={<BellNotifiedIcon />}
              color={colors.blue.violet}
              squared
            />
          ) : (
            <OcietyTooltip title={dictionary.dashboard.notifyButtonText}>
              <ActionContainer
                className={classes.wideAction}
                icon={<BellIcon />}
                color={colors.orange.light}
                onClick={() => handleCustomerNotify(item.id)}
                loading={notifying}
                squared
              />
            </OcietyTooltip>
          )}
          <OcietyTooltip title={dictionary.dashboard.moveToServed}>
            <ActionContainer
              className={classes.wideAction}
              icon={<DoneIcon />}
              color={colors.green.lighter}
              onClick={() => handleCustomerComplete(item)}
              loading={completing}
              squared
            />
          </OcietyTooltip>
        </>
      );
    }
  }, [
    renewing,
    values.isFinished,
    values.isServed,
    classes.wideAction,
    classes.renewIcon,
    handleCustomerRenew,
    item,
    handleCustomerDelete,
    isNotified,
    notifying,
    completing,
    handleCustomerNotify,
    handleCustomerComplete,
  ]);

  const parsedLabels = helpers.parseLabels(labels).join(", ");

  return (
    <Paper
      className={classes.root}
      {...provided.draggableProps}
      ref={provided.innerRef}
      {...provided.dragHandleProps}>
      <div className={classes.dragIconWrapper}>
        <DragIcon />
      </div>
      {!values.isFinished && (
        <div className={classes.indexWrapper}>{index}</div>
      )}
      <div className={classes.cellWrapper}>
        <TableCell className={globalClasses.width25}>
          <div className={classes.nameWrapper}>
            <OcietyTooltip title={name}>
              <Typography component="span" className={classes.name}>
                {name}
              </Typography>
            </OcietyTooltip>
            <div className={classes.badgeWrapper}>
              <StatusBadge
                status={status}
                notified={isNotified}
                notifiedAt={notifiedAt}
              />
              {timesUsed > 1 && (
                <Typography component="small" className={classes.timesUsed}>
                  {dictionary.dashboard.beenHere.replace(
                    "{times}",
                    timesUsed.toString(10)
                  )}
                </Typography>
              )}
            </div>
          </div>
        </TableCell>
        <TableCell className={globalClasses.width10}>
          <ServedIcon
            className={clsx(classes.servedIcon, classes.metricIcon)}
          />
          <Typography component="span" className={classes.metricText}>
            {quantity}
          </Typography>
        </TableCell>
        <TableCell
          className={clsx(globalClasses.width15, {
            [classes.inQueueRed]: values.isOvertime,
          })}>
          <ClockIcon
            className={clsx(classes.inQueueIcon, classes.metricIcon)}
          />
          <Typography component="span" className={classes.metricText}>
            {values.minutesWaiting} {dictionary.dashboard.minLabel}
          </Typography>
        </TableCell>
        <TableCell className={globalClasses.width10}>
          <SandclockIcon
            className={clsx(classes.metricIcon, classes.quotedIcon)}
          />
          <Typography component="span" className={classes.metricText}>
            {quotedTime} {dictionary.dashboard.minLabel}
          </Typography>
        </TableCell>
        <TableCell className={clsx(globalClasses.width15, classes.labelsCell)}>
          <OcietyTooltip title={parsedLabels}>
            <Typography component="span" className={classes.labels}>
              {parsedLabels}
            </Typography>
          </OcietyTooltip>
        </TableCell>
        <TableCell
          className={clsx(globalClasses.width25, classes.actionsCell, {
            [classes.cancelledActionsCell]: values.isFinished,
          })}>
          <div
            className={clsx(classes.actionsWrapper, {
              [classes.cancelledActionsWrapper]: values.isCancelled,
            })}>
            {actions}
          </div>
        </TableCell>
      </div>
      {!values.isFinished && (
        <ThreeDotsMenu
          onEditClick={() => handleCustomerEdit(item)}
          onDeleteClick={() => handleCustomerDelete(item.id)}
        />
      )}
    </Paper>
  );
};

export default QueueItem;
