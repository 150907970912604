import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";
import { colors } from "../../../../theme/colors";
import { SIDEBAR_WIDTH } from "./index";

export type ClassNames =
  | "drawer"
  | "drawerOpen"
  | "drawerClose"
  | "drawerDesktop"
  | "root"
  | "logo"
  | "modal"
  | "sidebarTop"
  | "nav"
  | "logoutIcon"
  | "logoutLabel"
  | "previewImage";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      drawer: {
        position: "relative",
        padding: theme.spacing(1.25),
        overflowX: "hidden",
        backgroundColor: colors.white.primary,
        color: colors.white.primary,
        borderRight: `1px solid ${colors.grey.dividerLighter}`,
      },
      drawerOpen: {
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: SIDEBAR_WIDTH,
        justifyContent: "space-between",
      },
      drawerClose: {
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        justifyContent: "space-between",
        width: theme.spacing(82 / 8),
      },
      drawerDesktop: {
        width: theme.spacing(82 / 8),
      },
      root: {
        backgroundColor: theme.palette.background.default,
        height: "100vh",
      },
      logo: {
        "& > g": {
          fill: colors.red.pink,
        },
      },
      modal: {
        backgroundColor: "rgba(0,0,0,.4)",
      },
      sidebarTop: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(15),
        padding: theme.spacing(0, 1),
      },
      nav: {
        height: "auto",
        [theme.breakpoints.up("sm")]: {
          marginTop: theme.spacing(5),
        },
      },
      logoutIcon: {
        margin: theme.spacing(3, "auto"),
        cursor: "pointer",
      },
      logoutLabel: {
        marginLeft: 25,
      },
      previewImage: {
        borderRadius: "100%",
        width: 44,
        height: 44,
        objectFit: "cover",
        [theme.breakpoints.down("xs")]: {
          maxWidth: "unset",
          maxHeight: "unset",
          width: "25vw",
          height: "25vw",
          marginBottom: theme.spacing(4),
        },
      },
    })
);
