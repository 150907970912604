import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../../common/models";
import { colors } from "../../../../../../../../theme/colors";

export type ClassNames =
  | "action"
  | "squared"
  | "transparent"
  | "fluid"
  | "loader"
  | "withText"
  | "text";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      action: {
        borderRadius: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 44,
        height: 44,
        cursor: "pointer",
      },
      squared: {
        borderRadius: 4,
      },
      transparent: {
        backgroundColor: "transparent",
        border: "1px solid",
      },
      fluid: {
        maxWidth: "unset !important",
        width: "100% !important",
      },
      loader: {
        color: colors.white.primary,
      },
      withText: {
        // width: "unset !important",
        padding: theme.spacing(2),
      },
      text: {
        marginLeft: theme.spacing(1),
        color: colors.white.primary,
        fontWeight: 700,
      },
    })
);
