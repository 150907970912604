import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../../../../common/models";
import { colors } from "../../../../../../../../../../theme/colors";

export type ClassNames = "threeDots" | "paperClass" | "menuWrapper" | "row";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      threeDots: {
        position: "absolute",
        right: "3%",
      },
      paperClass: {
        backgroundColor: colors.grey.primary,
        minWidth: 180,
        minHeight: 120,
        height: 1,
      },
      menuWrapper: {
        height: "100%",
        color: colors.white.primary,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(17),
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
      },
      row: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        "& > span": {
          marginLeft: 20,
        },
      },
    })
);
