import { Drawer, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import clsx from "clsx";
import React, { FC, useContext } from "react";
import { useHistory } from "react-router-dom";

import Endpoints from "../../../../common/api/endpoints";
import { SettingsResponse } from "../../../../common/api/settings/models";
import { VENUE_LOCK } from "../../../../common/api/settings/requests";
import Logo from "../../../../common/components/Logo";
import { handleLogoutClick } from "../../../../common/helpers";
import { useRestApi } from "../../../../common/hooks/useRestApi";
import { OcietyTheme } from "../../../../common/models";
import { useGlobalStyles } from "../../../../common/styles";
import { ReactComponent as LockIcon } from "../../../../common/svg/icons/lock.svg";
import { ReactComponent as LogoutIcon } from "../../../../common/svg/icons/logout.svg";
import { Route } from "../../../../config/router";
import dictionary from "../../../../i18n/en_US/dictionary";
import { actions, store } from "../../../../store";
import Actions from "../../../../store/actions";
import SidebarNav from "./components/SidebarNav";
import { bottomPages, Page, topPages } from "./pages";
import Props from "./Props";
import { useStyles } from "./styles";

export const SIDEBAR_WIDTH = "290px";

const Sidebar: FC<Props> = (props: Props) => {
  const { variant, className, open, toggleSidebar } = props;
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  const { dispatch, state } = useContext(store);

  const theme: OcietyTheme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const history = useHistory();

  const [, logout] = useRestApi<boolean, { venueId: number }>(
    Endpoints.LOGOUT,
    "POST"
  );

  const [, lock] = useRestApi<SettingsResponse>(
    VENUE_LOCK(state.venueId as number),
    "POST"
  );

  const handleLogout = async () => {
    dispatch({ type: Actions.SetIsLoading, payload: true });
    history.push(Route.Login);
    await logout({ venueId: state.venueId as number });
    handleLogoutClick(dispatch, history)();
    dispatch({ type: Actions.SetIsLoading, payload: false });
  };

  const logoutLink: Page = {
    icon: LogoutIcon,
    iconActive: LogoutIcon,
    label: "Log out",
    onClick: handleLogout,
    iconStyles: { marginLeft: 5 },
    labelClassName: classes.logoutLabel,
  };
  const lockIcon: Page = {
    disabled: !state.settings?.lockPassword,
    icon: LockIcon,
    iconActive: LockIcon,
    label: "Lock the screen",
    onClick: async () => {
      dispatch({ type: actions.SetLockingRequest, payload: true });
      const data = await lock();
      dispatch({ type: actions.SetSettings, payload: data });
      dispatch({ type: actions.SetLockingRequest, payload: false });
    },
  };

  const additionalBottomPages = [lockIcon, ...bottomPages, logoutLink];

  return (
    <Drawer
      onBackdropClick={toggleSidebar}
      anchor="left"
      ModalProps={{
        className: classes.modal,
      }}
      PaperProps={{
        style: { borderRadius: 0 },
      }}
      classes={{
        paper: clsx({
          [classes.drawer]: true,
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
          [classes.drawerDesktop]: isDesktop,
        }),
      }}
      className={clsx(classes.root, className)}
      open={open}
      variant={variant}>
      <div>
        <div className={clsx(globalClasses.flexRow_Center, classes.sidebarTop)}>
          {state.settings?.photoPath ? (
            <img
              src={state.settings?.photoPath}
              alt={dictionary.settings.venueLogo}
              className={classes.previewImage}
            />
          ) : (
            <Logo className={classes.logo} />
          )}
        </div>
        <SidebarNav
          className={classes.nav}
          pages={topPages}
          isSidebarOpen={open}
          toggleSidebar={toggleSidebar}
        />
      </div>
      <div className={globalClasses.flexColumn}>
        <SidebarNav
          className={classes.nav}
          pages={additionalBottomPages}
          isSidebarOpen={open}
          toggleSidebar={toggleSidebar}
        />
      </div>
    </Drawer>
  );
};

export default Sidebar;
