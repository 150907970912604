import { Paper } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";

import Props from "./Props";
import { useStyles } from "./styles";

const OcietyCard: FC<Props> = (props: Props) => {
  const { children, className, fluid } = props;
  const classes = useStyles();

  return (
    <Paper
      classes={{ root: classes.root }}
      className={clsx(className, { [classes.fluid]: fluid })}>
      {children}
    </Paper>
  );
};

export default OcietyCard;
