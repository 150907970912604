import DateFnsUtils from "@date-io/dayjs";
import {
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import clsx from "clsx";
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { withRouter } from "react-router-dom";

import DefaultLogo from "../../assets/images/default-logo.png";
import {
  SettingsRequest,
  SettingsResponse,
} from "../../common/api/settings/models";
import {
  SETTINGS,
  SETTINGS_MENU,
  SETTINGS_PHOTO,
} from "../../common/api/settings/requests";
import { AlertContext } from "../../common/components/Alert/AlertContextProvider";
import ButtonPrimary from "../../common/components/ButtonPrimary";
import ButtonSecondary from "../../common/components/ButtonSecondary";
import OcietyCard from "../../common/components/OcietyCard";
import OcietyTooltip from "../../common/components/OcietyTooltip";
import SelectIcon from "../../common/components/SelectIcon";
import TitleSection from "../../common/components/TitleSection";
import { useRestApi } from "../../common/hooks/useRestApi";
import HttpStatusCodes from "../../common/httpStatusCodes";
import { useGlobalStyles } from "../../common/styles";
import { ReactComponent as RemoveIcon } from "../../common/svg/icons/close.svg";
import { ReactComponent as InfoIcon } from "../../common/svg/icons/infoIcon.svg";
import { ReactComponent as PdfFile } from "../../common/svg/icons/pdfFile.svg";
import { ReactComponent as WarningIcon } from "../../common/svg/icons/warning-circle.svg";
import { ReactComponent as PdfLogo } from "../../common/svg/pdf.svg";
import {
  getLocalDayjsFromUTCTimeString,
  getUTCTimeStringFromDate,
} from "../../common/utils/dateTimeUtils";
import dictionary from "../../i18n/en_US/dictionary";
import { store } from "../../store";
import Actions from "../../store/actions";
import Props from "./Props";
import { useStyles } from "./styles";

type FileOrNull = File | null;
type ImagePreview = { preview: string | ArrayBuffer | null; file: FileOrNull };

// @ts-ignore
type SaveButtonText = dictionary.settings.save | dictionary.settings.saved;

const Settings: FC<Props> = (props: Props) => {
  const { toggleSidebar } = props;
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const { showAlert } = useContext(AlertContext);

  const { dispatch, state } = useContext(store);

  const [saveButtonText, setSaveButtonText] = useState<SaveButtonText>(
    dictionary.settings.save as SaveButtonText
  );

  const [file, setFile] = useState<ImagePreview>({
    preview: state.settings?.photoPath || DefaultLogo,
    file: null,
  });

  const [menuFile, setMenuFile] = useState<FileOrNull>(null);

  const [password, setPassword] = useState<string>(
    state.settings?.lockPassword || ""
  );

  useEffect(() => {
    setPassword(state.settings?.lockPassword || "");
  }, [state.settings?.lockPassword]);

  const [updatedPhoto, updatePhoto] = useRestApi<SettingsResponse, FormData>(
    SETTINGS_PHOTO(state.venueId as number),
    "PUT",
    undefined,
    {
      exposeErrorObject: true,
    }
  );

  const [, fetchSettings] = useRestApi<SettingsResponse>(
    SETTINGS(state.venueId as number),
    "GET"
  );

  const [updatedSettings, updateSettings] = useRestApi<
    SettingsResponse,
    SettingsRequest
  >(SETTINGS(state.venueId as number), "PUT");

  const [updatedMenu, updateMenu] = useRestApi<any, FormData>(
    SETTINGS_MENU(state.venueId as number),
    "POST"
  );

  const [deletedMenu, deleteMenu] = useRestApi<any>(
    SETTINGS_MENU(state.venueId as number),
    "DELETE"
  );

  const fileInputRef = useRef<HTMLInputElement>(null);
  const menuInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const reader: FileReader = new FileReader();

    const file: FileOrNull = e.target.files && e.target.files[0];

    if (file) {
      reader.onload = () => {
        const formData = new FormData();
        formData.append("photo", file);
        updatePhoto(formData).then((res) => {
          // @ts-ignore
          if (!res.response) return null;
          // @ts-ignore
          if (res.response.status === HttpStatusCodes.BAD_REQUEST) {
            return null;
          }
          // @ts-ignore
          if (res.response.status) return null;

          setFile({
            file,
            preview: reader.result,
          });

          return res;
        });
      };

      reader.readAsDataURL(file as File);
    }
  };

  const handleMenuChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file: FileOrNull = e.target.files && e.target.files[0];
    setMenuFile(file);
  };

  const setSettings = useCallback(
    (settings: SettingsResponse) =>
      dispatch({ type: Actions.SetSettings, payload: settings }),
    [dispatch]
  );

  const refreshSettings = useCallback(async () => {
    const data = await fetchSettings();

    dispatch({ type: Actions.SetSettings, payload: data || {} });
  }, [dispatch, fetchSettings]);

  useEffect(() => {
    refreshSettings();
  }, [refreshSettings, updatedSettings]);

  useEffect(() => {
    if (updatedPhoto.data) {
      setSettings(updatedPhoto.data);
    }
  }, [setSettings, dispatch, updatedPhoto.data]);

  useEffect(() => {
    setFile((prevState) => ({
      ...prevState,
      preview: state.settings?.photoPath || DefaultLogo,
    }));
  }, [state.settings?.photoPath]);

  useEffect(() => {
    if (menuFile) {
      const formData = new FormData();
      formData.append("menu", menuFile);

      updateMenu(formData)
        .then(refreshSettings)
        .catch(() => {
          // reset menu input
          if (menuInputRef.current) {
            menuInputRef.current.value = "";
          }
        });
    }
  }, [updateMenu, menuFile, refreshSettings]);

  const handleChangeClick = () => fileInputRef?.current?.click();
  const handleMenuChangeClick = () => menuInputRef?.current?.click();

  const handleRemoveClick = async () => {
    setFile({ file: null, preview: DefaultLogo });
    updateSettings({ photoPath: "" });
  };
  const handleSavePassClick = () => {
    updateSettings({ lockPassword: password });
    setSaveButtonText(dictionary.settings.saved as SaveButtonText);

    setTimeout(
      () => setSaveButtonText(dictionary.settings.save as SaveButtonText),
      2000
    );
  };

  const handleSaveWorkDayEndTime = (date: MaterialUiPickersDate) => {
    updateSettings({ workDayEndTime: getUTCTimeStringFromDate(date) });
  };

  const changeCurrentVenue = (event: any) => {
    const venueId = event.target.value;
    dispatch({ type: Actions.SetVenueId, payload: venueId });
    dispatch({ type: Actions.SetCustomers, payload: [] });
    localStorage.setItem("venueId", venueId);
  };

  const handlePasswordChange = (password: string) => {
    if (password.match(/^\d+$/g) || password === "") {
      setPassword(password);
    }
  };

  const handleMenuRemove = () => {
    if (menuInputRef.current?.value) {
      menuInputRef.current.value = "";
      setMenuFile(null);
    }

    deleteMenu().then(refreshSettings);
  };

  const getSizeInMb = (bytes: number) =>
    Math.round((bytes / (1024 * 1024)) * 100) / 100;

  const venuesOptions = state.settings?.venues || [];

  const menuLoading = updatedMenu.loading || deletedMenu.loading;

  return (
    <div className={classes.content}>
      <TitleSection
        toggleSidebar={toggleSidebar}
        className={classes.topNav}
        name={dictionary.settings.waitlistSettings}
      />
      <OcietyCard className={classes.contentCard} fluid>
        <Grid container>
          <Grid item className={classes.photoSection}>
            <Typography variant="h3">{dictionary.settings.photo}</Typography>
            <div className={classes.buttonsWrapper}>
              <input
                type="file"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleFileChange}
                accept=".jpg,.jpeg,.png"
              />
              <img
                src={file.preview as string}
                alt={dictionary.settings.venueLogo}
                className={classes.previewImage}
              />
              <ButtonPrimary
                className={classes.changeButton}
                onClick={handleChangeClick}>
                {dictionary.settings.change}
              </ButtonPrimary>
              <ButtonSecondary
                className={classes.removeButton}
                onClick={handleRemoveClick}>
                {dictionary.settings.remove}
              </ButtonSecondary>
            </div>
          </Grid>
          <Grid item className={classes.passcodeSection}>
            <Typography variant="h3">
              {dictionary.settings.screenLockCode}
            </Typography>
            <Grid
              container
              className={classes.passcodeButtonsWrapper}
              spacing={2}>
              <Grid item xs={12} sm="auto">
                <TextField
                  onChange={(e) => handlePasswordChange(e.target.value)}
                  value={password}
                  variant="outlined"
                  placeholder={dictionary.settings.enterNewPasscode}
                  className={classes.passcodeTextField}
                  InputProps={{
                    inputProps: {
                      className: classes.passcodeInput,
                      maxLength: 6,
                    },
                  }}
                  type="password"
                />
              </Grid>
              <Grid item xs={12} sm="auto">
                <ButtonPrimary
                  className={classes.saveButton}
                  onClick={handleSavePassClick}
                  disabled={!password.length || updatedSettings.loading}>
                  {saveButtonText}
                </ButtonPrimary>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.passcodeSection}>
            <Typography variant="h3">
              {dictionary.settings.selectVenue}
            </Typography>
            <Grid
              container
              className={classes.passcodeButtonsWrapper}
              spacing={2}>
              <Grid item xs={12} sm="auto">
                <FormControl
                  variant="outlined"
                  className={clsx(classes.selectWrapper, "select-no-legend")}>
                  <Select
                    className={classes.selectElement}
                    IconComponent={(iconProps) => <SelectIcon {...iconProps} />}
                    classes={{ select: classes.select }}
                    value={state.venueId}
                    label={dictionary.settings.selectVenue}
                    onChange={changeCurrentVenue}>
                    {venuesOptions.map((venue) => (
                      <MenuItem key={venue.id} value={venue.id}>
                        {venue.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.passcodeSection}>
            <Typography variant="h3">
              {dictionary.settings.resetDay}{" "}
              <OcietyTooltip
                title={dictionary.settings.resetDayHint}
                placement="top-start">
                <InfoIcon />
              </OcietyTooltip>
            </Typography>
            <Grid container className={classes.passcodeButtonsWrapper}>
              <Grid item xs={12} sm="auto">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <TimePicker
                    className={classes.passcodeTextField}
                    inputVariant="outlined"
                    ampm
                    value={getLocalDayjsFromUTCTimeString(
                      state.settings?.workDayEndTime
                    )}
                    onChange={handleSaveWorkDayEndTime}
                    InputProps={{
                      inputProps: {
                        className: classes.timeInput,
                      },
                    }}
                  />
                  <div className={classes.iconWrapper}>
                    <AccessTimeIcon className={classes.timeIcon} />
                  </div>
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            className={clsx(
              classes.passcodeSection,
              classes.sectionBorderTop,
              classes.menuSection
            )}>
            <Grid
              container
              direction="column"
              alignItems={
                !state.settings?.venue?.menu || menuLoading
                  ? "center"
                  : "flex-start"
              }
              spacing={2}
              className={clsx(
                classes.sectionMargin,
                classes.menuSectionContainer
              )}>
              <input
                type="file"
                style={{ display: "none" }}
                ref={menuInputRef}
                onChange={handleMenuChange}
                accept="application/pdf"
              />
              {!menuLoading ? (
                <>
                  {state.settings?.venue?.menu ? (
                    <>
                      <Typography variant="h3">
                        {dictionary.settings.menu}
                      </Typography>
                      <Grid container direction="row">
                        <Paper className={classes.menuFileCard}>
                          <PdfFile className={classes.pdfFileIcon} />
                          <div className={classes.menuFileMetadata}>
                            <Typography variant="h3">
                              {state.settings.venue.menu.fileName}
                            </Typography>
                            <Typography variant="subtitle2">
                              {getSizeInMb(state.settings.venue.menu.size)} MB
                            </Typography>
                          </div>
                          <IconButton
                            className={classes.removeMenuButton}
                            onClick={handleMenuRemove}>
                            <RemoveIcon />
                          </IconButton>
                        </Paper>
                        {!state.settings?.venue?.menu.active && (
                          <div className={classes.warningIconContainer}>
                            <OcietyTooltip
                              title={dictionary.settings.pendingTooltip}>
                              <WarningIcon />
                            </OcietyTooltip>
                          </div>
                        )}
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item className={classes.pdfIcon}>
                        <PdfLogo />
                      </Grid>
                      <Grid item>
                        <Typography variant="h2">
                          {dictionary.settings.uploadMenu}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle2">
                          {dictionary.settings.fileSize}
                        </Typography>
                      </Grid>
                    </>
                  )}
                  <ButtonPrimary
                    className={clsx(
                      classes.saveButton,
                      classes.uploadMenuButton
                    )}
                    onClick={handleMenuChangeClick}>
                    {!state.settings?.venue?.menu
                      ? dictionary.settings.uploadFile
                      : dictionary.settings.changeFile}
                  </ButtonPrimary>
                </>
              ) : (
                <CircularProgress className={globalClasses.marginAuto} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </OcietyCard>
    </div>
  );
};

// @ts-ignore
export default withRouter(Settings);
