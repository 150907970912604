import { replaceParams } from "../../helpers";
import Endpoints from "../endpoints";

export const SETTINGS = (venueId: number) =>
  replaceParams(Endpoints.SETTINGS, { venueId });

export const SETTINGS_PHOTO = (venueId: number) =>
  replaceParams(Endpoints.SETTINGS_PHOTO, { venueId });

export const SETTINGS_MENU = (venueId: number) =>
  replaceParams(Endpoints.SETTINGS_MENU, { venueId });

export const VENUE_LOCK = (venueId: number) =>
  replaceParams(Endpoints.VENUE_LOCK, { venueId });

export const VENUE_UNLOCK = (venueId: number) =>
  replaceParams(Endpoints.VENUE_UNLOCK, { venueId });
