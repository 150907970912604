import { Grid, Typography, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import clsx from "clsx";
import React, { FC, useMemo, useState } from "react";

import { colors } from "../../../theme/colors";
import ActionContainer from "../../../views/Dashboard/components/DashboardData/components/QueueTable/components/ActionContainer";
import { OcietyTheme } from "../../models";
import { ReactComponent as BurgerIcon } from "../../svg/icons/burger.svg";
import { ReactComponent as LoupeIcon } from "../../svg/icons/loupe.svg";
import { ReactComponent as PlusIcon } from "../../svg/icons/plus.svg";
import SearchBar from "./components/SearchBar";
import Props from "./Props";
import { useStyles } from "./styles";

const TitleSection: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const {
    name,
    toggleSidebar,
    withSearchbar,
    noTopMargin,
    className,
    fullWidth,
    setModalOpen,
    withAddButton,
    onSearchChange,
  } = props;

  const theme: OcietyTheme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [showSearch, setShowSearch] = useState<boolean>(false);

  const searchButton = useMemo(
    () => (
      <div onClick={() => setShowSearch(true)} className={classes.searchButton}>
        <LoupeIcon />
      </div>
    ),
    [classes.searchButton]
  );

  const searchBar = useMemo(
    () => (
      <SearchBar
        onClear={() => {
          setShowSearch(false);
          if (onSearchChange) {
            onSearchChange("");
          }
        }}
        onSearch={onSearchChange}
      />
    ),
    [onSearchChange]
  );

  const title = useMemo(
    () => (
      <Grid item lg={9} md={8} sm="auto" className={classes.nameWrapper}>
        <Typography variant="h1" className={classes.name}>
          {name}
        </Typography>
      </Grid>
    ),
    [classes.name, classes.nameWrapper, name]
  );

  const addButton = useMemo(
    () => (
      <ActionContainer
        className={classes.addButton}
        color={colors.green.lighter}
        icon={<PlusIcon />}
        onClick={() => (setModalOpen ? setModalOpen(true) : false)}
        text="Add New Guest"
        squared
      />
    ),
    [classes.addButton, setModalOpen]
  );

  return (
    <Grid
      container
      className={clsx(
        classes.titleSection,
        {
          [classes.topMargin]: !noTopMargin,
          [classes.fullWidth]: fullWidth,
        },
        className
      )}>
      {isMobile && (
        <Grid item className={classes.topNavigation}>
          {!showSearch && <BurgerIcon onClick={toggleSidebar} />}
          {withSearchbar && (
            <div
              className={clsx(classes.topNavigationControls, {
                [classes.fullWidthControls]: showSearch,
              })}>
              {showSearch && searchBar}
              {!showSearch && searchButton}
              {withAddButton && !showSearch && addButton}
            </div>
          )}
        </Grid>
      )}
      {showSearch && !isMobile && searchBar}
      {name && (isMobile ? title : !showSearch && title)}
      {!isMobile && withSearchbar && (
        <div className={classes.topNavigationControls}>
          {!showSearch && searchButton}
          {withAddButton && addButton}
        </div>
      )}
    </Grid>
  );
};

export default TitleSection;
