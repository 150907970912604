import { CircularProgress } from "@material-ui/core";
import React, { FC } from "react";

import BackgroundImage from "../../../../assets/images/dashboard-bg.png";
import ButtonPrimary from "../../../../common/components/ButtonPrimary";
import TitleSection from "../../../../common/components/TitleSection";
import { useGlobalStyles } from "../../../../common/styles";
import { ReactComponent as AddIcon } from "../../../../common/svg/icons/add.svg";
import dictionary from "../../../../i18n/en_US/dictionary";
import Props from "./Props";
import { useStyles } from "./styles";

const EmptyDashboard: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  const { toggleSidebar, setModalOpen, loading } = props;

  return (
    <div className={classes.content}>
      <TitleSection
        toggleSidebar={toggleSidebar}
        noTopMargin
        className={classes.topNav}
      />
      <img src={BackgroundImage} alt="" />
      <ButtonPrimary
        className={classes.addButton}
        onClick={() => setModalOpen(true)}>
        <AddIcon className={classes.addIcon} />
        {dictionary.dashboard.addGuest}
      </ButtonPrimary>
      {loading && (
        <CircularProgress size={15} className={globalClasses.marginAuto} />
      )}
    </div>
  );
};

export default EmptyDashboard;
