import { Tooltip } from "@material-ui/core";
import React, { FC } from "react";

import Props from "./Props";
import { useStyles } from "./styles";

const OcietyTooltip: FC<Props> = (props: Props) => {
  const { children, title, ...rest } = props;
  const classes = useStyles();

  return (
    <Tooltip
      arrow
      title={title}
      classes={{ tooltip: classes.tooltip }}
      enterTouchDelay={0}
      {...rest}>
      {children}
    </Tooltip>
  );
};

export default OcietyTooltip;
