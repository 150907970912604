import { useCallback, useState } from "react";

import { Form, UseFormTypes } from "../models";
import { validate } from "../utils/validation";

/**
 * Hook for forms state store and validation
 *
 * @param schema
 * @param defaultForm
 */
export function useForm<
  S extends Record<string, Record<string, any>>,
  F extends Form
>(schema: S, defaultForm: F): UseFormTypes<S, F> {
  const [form, setForm] = useState(defaultForm);

  const setValue = useCallback(
    (prevForm: F, key: keyof S, value: string): F => {
      return {
        ...prevForm,
        values: {
          ...prevForm.values,
          [key]: value,
        },
      };
    },
    []
  );

  const setValues = useCallback(
    (prevForm: F, values: { [key in keyof S]: string }): F => {
      return {
        ...prevForm,
        values: {
          ...prevForm.values,
          ...values,
        },
      };
    },
    []
  );

  const validateField = useCallback(
    (key: keyof S, value: string, newForm: F): F => {
      // @ts-ignore
      const error: string = validate(key, value, schema, newForm);

      newForm = {
        ...newForm,
        isValid: false,
        errors: {
          ...newForm.errors,
          [key]: error,
        },
      };

      newForm.isValid = !Object.values(newForm.errors).some(
        (err: string) => !!err
      );

      return newForm;
    },
    [schema]
  );

  const validateForm = useCallback((): boolean => {
    const newForm = { ...form };
    const values = form.values;

    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        newForm.errors[key] = validate(key, values[key], schema, newForm);
      }
    }

    newForm.isValid = !Object.values(newForm.errors).some(
      (err: string) => !!err
    );

    setForm(newForm);

    return newForm.isValid;
  }, [schema, form]);

  const setAndValidate = useCallback(
    (key: keyof S, value: string) => {
      setForm((prevForm) =>
        validateField(key, value, setValue(prevForm, key, value))
      );
    },
    [setValue, validateField]
  );

  const setFormValue = useCallback(
    (key: keyof S, value: string): void => {
      setForm((prevForm) => setValue(prevForm, key, value));
    },
    [setValue]
  );

  const setFormValues = useCallback(
    (values: { [key in keyof S]: string }) => {
      setForm((prevForm) => setValues(prevForm, values));
    },
    [setValues]
  );

  const setSchemaRules = useCallback(
    (key: string, schamaItem: any) => {
      // @ts-ignore
      schema[key] = schamaItem;
    },
    [schema]
  );

  return {
    form,
    setAndValidate,
    validateForm,
    setFormValue,
    setFormValues,
    setSchemaRules,
  };
}
