import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../../common/models";
import { colors } from "../../../../../../../../theme/colors";

export type ClassNames =
  | "root"
  | "cellWrapper"
  | "dragIconWrapper"
  | "indexWrapper"
  | "nameWrapper"
  | "servedIcon"
  | "metricText"
  | "metricIcon"
  | "inQueueRed"
  | "inQueueIcon"
  | "quotedIcon"
  | "labels"
  | "actionsWrapper"
  | "actionsCell"
  | "labelsCell"
  | "cancelledActionsCell"
  | "cancelledActionsWrapper"
  | "timesUsed"
  | "name"
  | "badgeWrapper"
  | "tooltip"
  | "wideAction"
  | "renewIcon";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        height: 88,
        position: "relative",
        display: "flex",
        boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)",
        alignItems: "center",
        marginBottom: theme.spacing(2),
      },
      cellWrapper: {
        height: "100%",
        display: "flex",
        width: "75%",
        margin: "0 auto",
        "& td": {
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        },
      },
      dragIconWrapper: {
        position: "absolute",
        left: theme.spacing(3),
        cursor: "move !important",
      },
      indexWrapper: {
        background: "rgba(95, 98, 116, 0.05)",
        width: 48,
        height: 48,
        position: "absolute",
        left: "7%",
        color: colors.grey.primary,
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.pxToRem(20),
        fontWeight: 600,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 8,
      },
      nameWrapper: {
        height: 48,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-start",
        "& > span": {
          fontSize: theme.typography.pxToRem(18),
          fontWeight: "bold",
        },
      },
      servedIcon: {
        width: 20,
        height: 20,
      },
      inQueueRed: {
        "& > span": {
          color: colors.red.primary,
        },
        "& > svg": {
          "& > *": {
            fillOpacity: 1,
            fill: colors.red.primary,
          },
        },
      },
      metricText: {
        fontSize: theme.typography.pxToRem(16),
        whiteSpace: "nowrap",
      },
      metricIcon: {
        "& > *": {
          fill: "rgba(95, 98, 116, 0.24)",
          "& > *": {
            fill: "rgba(95, 98, 116, 0.24)",
          },
        },
        marginRight: 5,
      },
      inQueueIcon: {
        minWidth: 24,
        "& > *": {
          fillOpacity: 1,
        },
      },
      quotedIcon: {
        minWidth: 20,
      },
      labels: {
        fontSize: theme.typography.pxToRem(12),
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
      },
      actionsWrapper: {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
      actionsCell: {
        paddingRight: 0,
        [theme.breakpoints.up("lg")]: {
          width: "20%",
        },
      },
      labelsCell: {
        [theme.breakpoints.up("lg")]: {
          width: "20%",
        },
      },
      cancelledActionsCell: {
        marginLeft: "auto",
      },
      cancelledActionsWrapper: {
        justifyContent: "flex-end",
      },
      timesUsed: {
        fontSize: theme.typography.pxToRem(12),
        color: colors.grey.widgets,
        fontWeight: 500,
        marginLeft: theme.spacing(1),
        whiteSpace: "nowrap",
      },
      name: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "100%",
      },
      badgeWrapper: {
        display: "flex",
        alignItems: "center",
      },
      tooltip: {
        fontSize: theme.typography.pxToRem(14),
      },
      wideAction: {
        width: 64,
      },
      renewIcon: {
        fontSize: theme.typography.pxToRem(27),
        fill: colors.white.primary,
      },
    })
);
