import parsePhoneNumberFromString, { PhoneNumber } from "libphonenumber-js";
import validator from "validator";

import { validCountries } from "../../config";
import { BasicForm } from "../models";

interface LengthRule {
  max?: number;
  min?: number;
}

interface PresenceRule {
  allowEmpty: boolean;
  message: string;
}

export type FieldType =
  | "email"
  | "password"
  | "number"
  | "number+"
  | "phone"
  | "latitude"
  | "longitude";

type FormSchema = Record<string, ValidationRules>;

export interface ValidationRules {
  type?: FieldType | FieldType[];
  length?: LengthRule;
  required?: boolean;
  equal?: string;
  presence?: PresenceRule;
}

export function validate<S extends FormSchema, F extends BasicForm<any>>(
  key: keyof S,
  value: string,
  schema: S,
  form: F
): string {
  let error = "";
  const validationRules: ValidationRules = schema[key];

  const handleError = (errorString: string) => {
    // @ts-ignore
    error = key[0].toUpperCase() + key.slice(1, key.length) + " " + errorString;
  };

  if (!value) {
    if (validationRules.required) {
      handleError("is required");
    }

    return error;
  }

  // @ts-ignore
  for (const ruleKey: keyof ValidationRules in validationRules) {
    if (validationRules.hasOwnProperty(ruleKey)) {
      const rules = validationRules[ruleKey as keyof ValidationRules];

      switch (ruleKey) {
        case "type": {
          validateType(value, rules as FieldType | FieldType[], handleError);
          break;
        }
        case "length": {
          validateLength(value, rules as LengthRule, handleError);
          break;
        }
        case "equal": {
          const fieldToCompare = rules as string;

          validateEqual(
            value,
            fieldToCompare,
            // @ts-ignore
            form.values[fieldToCompare],
            handleError
          );
          break;
        }
        default: {
          // nothing
        }
      }
    }
  }

  return error;
}

function validateType(
  value: string,
  type: FieldType | FieldType[],
  handleError: (error: string) => void
): void {
  switch (type) {
    case "email": {
      if (!validator.isEmail(value)) {
        handleError("is not valid email");
      }
      break;
    }
    case "password": {
      if (
        !validator.matches(
          value,
          new RegExp(
            // eslint-disable-next-line max-len
            "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
          )
        )
      ) {
        handleError(
          "must contain at least one number and one" +
            " uppercase and lowercase letter, and at least 8" +
            " or more characters"
        );
      }
      break;
    }
    case "number": {
      if (!validator.isNumeric(value)) {
        handleError("is not valid number");
      }
      break;
    }
    case "number+": {
      if (!validator.isNumeric(value) || Number(value) <= 0) {
        handleError("is not positive number value");
      }
      break;
    }
    case "phone": {
      if (!validator.isMobilePhone(value)) {
        handleError("is not valid format");
      }
      break;
    }
    case "latitude": {
      validateLatitude(value, handleError);
      break;
    }
    case "longitude": {
      validateLongitude(value, handleError);
      break;
    }
  }
}

function validateLength(
  value: string,
  rules: LengthRule,
  handleError: (error: string) => void
) {
  const valueLength = value.length;
  const ruleMax = rules.max;
  const ruleMin = rules.min;

  if (ruleMax && ruleMax < valueLength) {
    handleError(`can't contain more than ${ruleMax} symbols`);
  }

  if (ruleMin && ruleMin > valueLength) {
    handleError(`can't contain less than ${ruleMin} symbols`);
  }
}

function validateEqual(
  value: string,
  comparisonFieldName: string,
  comparison: string,
  handleError: (error: string) => void
) {
  if (!validator.equals(value, comparison)) {
    handleError(`must be the same as ${comparisonFieldName}`);
  }
}

export function validateLatitude(
  latitude: string,
  handleError: (error: string) => void
) {
  if (!validator.isLatLong(latitude + ",-73.985130")) {
    handleError("is not valid coordinate");
  }
}

export function validateLongitude(
  longitude: string,
  handleError: (error: string) => void
) {
  if (!validator.isLatLong("40.758896," + longitude)) {
    handleError("is not valid coordinate");
  }
}

export function validatePhoneNumber(phoneNumber: string) {
  const checkedPhone: PhoneNumber | undefined =
    parsePhoneNumberFromString(phoneNumber);

  return !!(
    checkedPhone &&
    checkedPhone.isPossible() &&
    checkedPhone.country &&
    validCountries.split(",").includes(checkedPhone.country)
  );
}
