import { CircularProgress, fade, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";

import Props from "./Props";
import { useStyles } from "./styles";

const ActionContainer = (props: Props, ref: React.Ref<any>) => {
  const classes = useStyles();
  const {
    color,
    onClick,
    icon,
    className,
    squared,
    transparent,
    fluid,
    loading,
    text,
    ...rest
  } = props;

  const boxShadowColor = fade(color, transparent ? 0.08 : 0.24);
  const boxShadowShape = transparent ? "0px 4px 24px" : "0px 4px 10px";

  return (
    <div
      {...rest}
      ref={ref}
      className={clsx(
        classes.action,
        {
          [classes.squared]: squared,
          [classes.transparent]: transparent,
          [classes.fluid]: fluid,
          [classes.withText]: text,
        },
        className
      )}
      style={{
        backgroundColor: !transparent ? color : "transparent",
        borderColor: transparent ? color : "unset",
        boxShadow: `${boxShadowShape} ${boxShadowColor}`,
      }}
      onClick={onClick}>
      {loading ? (
        <CircularProgress size={20} className={classes.loader} />
      ) : (
        icon
      )}
      {text && (
        <Typography variant="h5" className={classes.text}>
          {text}
        </Typography>
      )}
    </div>
  );
};

export default React.forwardRef(ActionContainer);
