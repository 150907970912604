import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";
import { colors } from "../../../../theme/colors";

export type ClassNames =
  | "loader"
  | "label"
  | "modal"
  | "logoutIcon"
  | "stepIcon"
  | "stepActive"
  | "stepsWrapper"
  | "contentWrapper"
  | "buttonsRow"
  | "buttonsWrapper"
  | "button"
  | "shieldIconWrapper"
  | "stepsSection"
  | "cancelButton"
  | "zeroButton";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      loader: {
        margin: "auto",
      },
      label: {
        marginBottom: theme.spacing(2),
      },
      modal: {
        minWidth: "50%",
        height: 691,
        position: "relative",
        [theme.breakpoints.down("md")]: {
          width: "65%",
          maxWidth: "unset",
        },
        [theme.breakpoints.down("sm")]: {
          width: "75%",
          maxWidth: "unset",
        },
        [theme.breakpoints.down("xs")]: {
          width: "100%",
          maxHeight: "100%",
          height: "100%",
          margin: 0,
        },
      },
      logoutIcon: {
        position: "absolute",
        top: "16px",
        right: "16px",
        cursor: "pointer",
      },
      stepsWrapper: {
        margin: "0 auto",
        display: "flex",
        justifyContent: "center",
      },
      stepIcon: {
        width: 14,
        height: 14,
        borderRadius: "100%",
        border: `2px solid ${colors.grey.radio}`,
        margin: theme.spacing(0, 1),
      },
      stepActive: {
        border: "none",
        backgroundColor: colors.green.lighter,
      },
      contentWrapper: {
        flexDirection: "column",
      },
      buttonsRow: {
        display: "flex",
        justifyContent: "center",
        marginBottom: theme.spacing(3),
        "&:last-child": {
          justifyContent: "flex-end",
        },
      },
      buttonsWrapper: {
        margin: "0 auto",
        width: 252,
      },
      button: {
        backgroundColor: colors.grey.dividerLight,
        borderRadius: "100%",
        width: 68,
        height: 68,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: theme.typography.pxToRem(24),
        fontWeight: 600,
        fontFamily: theme.typography.fontFamily,
        cursor: "pointer",
        margin: theme.spacing(0, 1),
      },
      cancelButton: {
        width: 68,
        height: 68,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 600,
        fontFamily: theme.typography.fontFamily,
        cursor: "pointer",
        margin: theme.spacing(0, 1),
      },
      shieldIconWrapper: {
        justifyContent: "center",
        display: "flex",
      },
      stepsSection: {
        marginBottom: theme.spacing(3),
      },
      zeroButton: {
        marginLeft: "auto",
      },
    })
);
