import { TextField } from "@material-ui/core";
import React, { FC } from "react";
import InputMask from "react-input-mask";

import dictionary from "../../../i18n/en_US/dictionary";
import Props from "./Props";
import { useStyles } from "./styles";

const PhoneNumberInput: FC<Props> = (props: Props) => {
  const { value, onChange, error, isWrongFormat } = props;
  const classes = useStyles();

  return (
    <InputMask mask="+1 (999) 999-9999" value={value} onChange={onChange}>
      {(inputProps: any) => (
        <TextField
          fullWidth
          name="phone"
          type="tel"
          variant="outlined"
          error={!!error || isWrongFormat}
          helperText={
            error ||
            (isWrongFormat && dictionary.guestModal.wrongFormatErrorText)
          }
          classes={{ root: classes.root }}
          required
          {...inputProps}
        />
      )}
    </InputMask>
  );
};

export default PhoneNumberInput;
