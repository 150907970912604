import Endpoints from "./api/endpoints";

export const mfaTokenKey = "mfa_token";
export const hasAccessToken = "has_token";

export const registerLink = "https://app.ociety.com/sdf04f3-fwdfsd-fe3efsd";
export const forgotPasswordLink = "https://app.ociety.com/forgot-password";

export const MS_IN_MINUTE = 60000;

export const excludedRefreshRoutes = [Endpoints.LOGIN];
