import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";
import { colors } from "../../../../theme/colors";

export type ClassNames =
  | "modalItem"
  | "modalPaper"
  | "checkboxLabel"
  | "inputLabel"
  | "controls"
  | "cancelButton"
  | "controlsWrapper"
  | "modalItemInput"
  | "saveButton"
  | "labelsWrapper"
  | "inputError";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      modalItem: {
        marginTop: theme.spacing(4),
        [theme.breakpoints.down("xs")]: {
          marginTop: 0,
        },
        "&, & label": {
          fontSize: theme.typography.pxToRem(16),
          fontStyle: "normal",
          color: colors.grey.secondary,
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
          borderColor: `${colors.red.primary} !important`,
        },
      },
      modalItemInput: {
        "& label": {
          color: `${colors.grey.modalText} !important`,
          fontWeight: 600,
        },
      },
      modalPaper: {
        maxWidth: "65%",
        [theme.breakpoints.down("sm")]: {
          maxWidth: "100%",
        },
        [theme.breakpoints.down("xs")]: {
          maxWidth: "100%",
          maxHeight: "100%",
          height: "100%",
          margin: 0,
        },
        fontFamily: theme.typography.fontFamily,
        fontWeight: 600,
        fontStyle: "normal",
      },
      checkboxLabel: {
        fontSize: theme.typography.pxToRem(16),
        fontStyle: "normal",
        color: colors.grey.modalText,
        fontWeight: 600,
      },
      inputLabel: {
        display: "inline-block",
        marginBottom: 15,
      },
      controls: {
        fontSize: 14,
      },
      cancelButton: {
        border: `2px solid ${colors.grey.border}`,
        backgroundColor: colors.white.primary,
        color: colors.grey.modalText,
        width: 90,
        marginRight: theme.spacing(2),
        [theme.breakpoints.down("xs")]: {
          width: "100%",
          marginRight: 0,
        },
      },
      saveButton: {
        backgroundColor: colors.green.secondary,
        [theme.breakpoints.down("xs")]: {
          width: "100%",
          marginBottom: theme.spacing(2),
        },
        "&:$disabled": {
          opacity: ".7",
          color: colors.white.primary,
        },
      },
      controlsWrapper: {
        display: "flex",
        justifyContent: "flex-end",
        margin: theme.spacing(4, 0),
        [theme.breakpoints.down("xs")]: {
          flexDirection: "column-reverse",
        },
      },
      labelsWrapper: {
        [theme.breakpoints.down("xs")]: {
          marginTop: theme.spacing(4),
        },
      },
      inputError: {
        color: colors.red.primary,
        fontWeight: 100,
        fontSize: theme.typography.pxToRem(13),
        display: "block",
        marginTop: theme.spacing(1),
      },
    })
);
