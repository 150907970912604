import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../common/models";
import { colors } from "../../theme/colors";

type ClassNames =
  | "root"
  | "container"
  | "commonWrapper"
  | "footer"
  | "content"
  | "sidebar"
  | "mobile"
  | "noSidebar"
  | "blurry";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        height: "auto",
        display: "flex",
        flex: 1,
        maxWidth: "100%",
      },
      container: {
        height: "100vh",
        overflow: "auto",
      },
      content: {
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.grey.elementsLight,
        [theme.breakpoints.down("sm")]: {
          padding: theme.spacing(2),
          minHeight: "100%",
        },
        [theme.breakpoints.up("sm")]: {
          padding: theme.spacing(3),
          minHeight: "100%",
        },
        [theme.breakpoints.up("md")]: {
          padding: theme.spacing(5),
        },
      },
      commonWrapper: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        height: "100vh",
        maxWidth: "100%",
      },
      footer: {
        width: "100%",
      },
      mobile: {
        maxWidth: "100%",
        width: "100%",
      },
      sidebar: {
        width: "calc(100% - 290px)",
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      noSidebar: {
        width: "calc(100% - 82px)",
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
      blurry: {
        filter: "blur(25px)",
      },
    })
);
