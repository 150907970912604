import { TextField, Typography } from "@material-ui/core";
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useState,
} from "react";

import {
  LoginResponse,
  VerifyMFACodeRequest,
} from "../../../../common/api/auth/models";
import Endpoints from "../../../../common/api/endpoints";
import ButtonPrimary from "../../../../common/components/ButtonPrimary";
import ButtonSecondary from "../../../../common/components/ButtonSecondary";
import { mfaTokenKey } from "../../../../common/constants";
import { useForm } from "../../../../common/hooks/useForm";
import { useRestApi } from "../../../../common/hooks/useRestApi";
import HttpStatusCodes from "../../../../common/httpStatusCodes";
import dictionary from "../../../../i18n/en_US/dictionary";
import { store } from "../../../../store";
import Actions from "../../../../store/actions";
import { MFABasicForm, MFAFormField, schema } from "./form";
import Props from "./Props";

const MFA: FC<Props> = (props: Props) => {
  const { classes, rememberMe, mfaToken, venueId } = props;
  const [authError, setAuthError] = useState<string>("");

  const { dispatch } = useContext(store);

  const { form, setAndValidate, validateForm, setFormValues } = useForm(
    schema,
    MFABasicForm
  );

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name: key, value } = event.target;
    // @ts-ignore
    setAndValidate(key as MFAFormField, value);
  };

  const [, verifyMFA] = useRestApi<LoginResponse, VerifyMFACodeRequest>(
    Endpoints.VERIFY,
    "POST",
    undefined,
    { exposeErrorObject: true }
  );

  const handleAuthError = useCallback((e) => {
    let error: string = dictionary.auth.authError;

    if (e.response?.status === HttpStatusCodes.UNAUTHORIZED) {
      error = dictionary.auth.validationError;
    } else if (e.response?.status === HttpStatusCodes.SERVER_ERROR) {
      error = e.response?.data;
    }

    setAuthError(error);
  }, []);

  const handleVerifyMFACode = async () => {
    const mfaCode = form.values.code;

    if (!mfaCode || !mfaToken) {
      validateForm();
      return;
    }

    const loginResponse: LoginResponse | undefined = await verifyMFA({
      code: mfaCode,
      token: mfaToken,
      rememberMe,
      venueId,
    });

    if (loginResponse) {
      if (!(loginResponse instanceof Error)) {
        dispatch({
          type: Actions.SetAuthTokens,
          payload: {
            hasToken: true,
          },
        });
      } else {
        handleAuthError(loginResponse);
      }
    }
  };

  const cancelMFA = () => {
    setAuthError("");
    // @ts-ignore
    setFormValues(MFABasicForm.values);
    localStorage.removeItem(mfaTokenKey);
    dispatch({ type: Actions.SetMfaToken, payload: null });
  };

  return (
    <>
      <Typography className={classes.mfaDescription} variant="h5">
        {dictionary.auth.enterMfaToSignIn}
      </Typography>
      <TextField
        className={classes.textField}
        error={!!authError}
        fullWidth
        helperText={authError}
        label={dictionary.auth.mfaCodeFieldLabel}
        name="code"
        onChange={handleChange}
        type="number"
        value={form.values.code || ""}
        variant="outlined"
      />
      <div className={classes.actionsWrapper}>
        <ButtonPrimary
          className={classes.signInButton}
          disabled={!form.isValid}
          fullWidth
          onClick={handleVerifyMFACode}>
          {dictionary.auth.sendMFACodeButtonText}
        </ButtonPrimary>
        <ButtonSecondary onClick={cancelMFA} fullWidth>
          {dictionary.auth.cancelMfa}
        </ButtonSecondary>
      </div>
    </>
  );
};

export default MFA;
