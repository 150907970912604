import { BasicForm } from "../../../../common/models";
import { ValidationRules } from "../../../../common/utils/validation";

export type MFAFormField = "code";

interface IMFABasicForm extends BasicForm<string> {
  errors: {
    code: string;
  };
  values: {
    code: string;
  };
  touched: MFAFormField[];
}

type MFAFormSchemaType = Record<MFAFormField, ValidationRules>;

export const schema: MFAFormSchemaType = {
  code: {
    required: true,
    length: {
      max: 6,
    },
  },
};

export const MFABasicForm: IMFABasicForm = {
  errors: {
    code: "",
  },
  values: {
    code: "",
  },
  isValid: true,
  touched: [],
};
