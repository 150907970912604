import { TextField } from "@material-ui/core";
import React, { FC, useContext, useEffect, useState } from "react";

import dictionary from "../../../../../i18n/en_US/dictionary";
import { store } from "../../../../../store";
import { ReactComponent as ClearIcon } from "../../../../svg/icons/clear.svg";
import { ReactComponent as LoupeIcon } from "../../../../svg/icons/loupe.svg";
import Props from "./Props";
import { useStyles } from "./styles";

const SearchBar: FC<Props> = (props: Props) => {
  const [query, setQuery] = useState("");
  const classes = useStyles();
  const { state } = useContext(store);
  const { onClear, onSearch } = props;

  useEffect(() => setQuery(""), [state.customers]);
  useEffect(() => {
    if (onSearch) {
      onSearch(query);
    }
  }, [onSearch, query]);

  return (
    <TextField
      onChange={(e) => setQuery(e.target.value)}
      value={query}
      classes={{ root: classes.root }}
      type="text"
      variant="outlined"
      placeholder={dictionary.dashboard.searchPlaceholder}
      InputProps={{
        classes: {
          root: classes.inputRoot,
          notchedOutline: classes.notchedOutline,
        },
        inputProps: { className: classes.searchBar },
        startAdornment: <LoupeIcon className={classes.startAdornment} />,
        endAdornment: (
          <ClearIcon onClick={onClear} className={classes.endAdornment} />
        ),
      }}
    />
  );
};

export default SearchBar;
