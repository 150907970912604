import { Typography } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/styles";
import clsx from "clsx";
import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";

import { AuthCredentials, AuthResponse } from "../../common/api/auth/models";
import Endpoints from "../../common/api/endpoints";
import Logo from "../../common/components/Logo";
import { mfaTokenKey } from "../../common/constants";
import { useForm } from "../../common/hooks/useForm";
import { useRestApi } from "../../common/hooks/useRestApi";
import { Venue } from "../../common/models";
import { handleLoginError } from "../../common/utils/authUtils";
import { Route } from "../../config/router";
import dictionary from "../../i18n/en_US/dictionary";
import { store } from "../../store";
import Actions from "../../store/actions";
import LoginForm from "./components/LoginForm";
import { LoginBasicForm, schema } from "./components/LoginForm/form";
import MFA from "./components/MFA";
import SelectVenueList from "./components/SelectVenueList";
import Props from "./Props";
import { LoginClassNames, useStyles } from "./styles";

const Login: FC<Props> = () => {
  const history = useHistory<Record<string, any>>();
  const { state } = useContext(store);
  const { mfaToken, hasToken } = state;

  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [venueId, setVenueId] = useState<number | null>(null);
  const [chosenVenueId, setChosenVenueId] = useState<number | null>(null);
  const [venues, setVenues] = useState<Venue[]>([]);

  const { dispatch } = useContext(store);
  const loginForm = useForm(schema, LoginBasicForm);

  const toggleRememberMe = (): void => setRememberMe((prevState) => !prevState);

  const saveVenueId = useCallback(
    (venueId: number) => {
      dispatch({ type: Actions.SetVenueId, payload: venueId });
      localStorage.setItem("venueId", venueId.toString());
    },
    [dispatch]
  );

  const handleAuthError = useCallback((e) => {
    return handleLoginError(e.response);
  }, []);

  const [, authorize] = useRestApi<AuthResponse, AuthCredentials>(
    Endpoints.LOGIN,
    "POST",
    undefined,
    {
      exposeErrorObject: true,
    }
  );

  const handleAuth = useCallback(
    async (username: string, password: string) => {
      const authResponse = await authorize({
        username: username.trim(),
        password,
        rememberMe: rememberMe,
        venueId: chosenVenueId,
      });

      if (authResponse && !(authResponse instanceof Error)) {
        if ("venues" in authResponse) {
          setVenues(authResponse.venues);
        } else if ("twoFactor" in authResponse) {
          setVenueId(authResponse.venueId);
          saveVenueId(authResponse.venueId);
          dispatch({ type: Actions.SetMfaToken, payload: authResponse.token });
          localStorage.setItem(mfaTokenKey, authResponse.token);
        } else {
          setVenueId(authResponse.venueId);
          dispatch({
            type: Actions.SetAuthTokens,
            payload: {
              hasToken: true,
            },
          });
        }
      } else if (authResponse) {
        return handleAuthError(authResponse);
      }
    },
    [
      authorize,
      chosenVenueId,
      dispatch,
      handleAuthError,
      rememberMe,
      saveVenueId,
    ]
  );

  useEffect(() => {
    (async () => {
      if (chosenVenueId) {
        const values = loginForm.form.values;
        const { username, password } = values;
        await handleAuth(username as string, password as string);
        saveVenueId(chosenVenueId);
        setChosenVenueId(null);
        setVenues([]);
      }
    })();
  }, [chosenVenueId, handleAuth, loginForm.form.values, saveVenueId]);

  useEffect(() => {
    if (hasToken && venueId) {
      saveVenueId(venueId);

      history.push(Route.Dashboard);
    }
  }, [hasToken, dispatch, venueId, history, saveVenueId]);

  const classes: ClassNameMap<LoginClassNames> = useStyles();

  return !!venues?.length ? (
    <SelectVenueList
      venues={venues}
      onVenueClick={(id) => {
        setChosenVenueId(id);
      }}
    />
  ) : (
    <div className={classes.content}>
      <div className={classes.contentBody}>
        <div className={classes.form}>
          <div className={classes.contentHeader} style={{ userSelect: "none" }}>
            <Logo className={classes.logo} width={84} height={84} />
          </div>
          <div className={classes.contentHeader}>
            <Typography
              className={clsx(
                classes.title,
                mfaToken ? classes.mfaTitle : classes.loginTitle
              )}
              variant="h1">
              {mfaToken ? (
                <span>{dictionary.auth.mfaTitle}</span>
              ) : (
                <>
                  <span>{dictionary.auth.logIn}</span>
                  <span className={classes.titleRight}>
                    {dictionary.auth.toYourAccount}
                  </span>
                </>
              )}
            </Typography>
          </div>
          {mfaToken ? (
            <MFA
              classes={classes}
              mfaToken={mfaToken}
              rememberMe={rememberMe}
              venueId={venueId}
            />
          ) : (
            <LoginForm
              classes={classes}
              rememberMe={rememberMe}
              toggleRememberMe={toggleRememberMe}
              handleAuth={handleAuth}
              loginForm={loginForm}
            />
          )}
        </div>
      </div>
      <div className={classes.aside} />
    </div>
  );
};

export default withRouter(Login);
