import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../../common/models";
import { colors } from "../../../../../../../../theme/colors";

export type ClassNames = "badge" | "capitalized" | "bellIcon";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      badge: {
        borderRadius: 4,
        fontSize: theme.typography.pxToRem(12),
        fontFamily: theme.typography.fontFamily,
        color: colors.white.primary,
        padding: "3px 6px",
        fontWeight: 600,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        whiteSpace: "nowrap",
      },
      capitalized: {
        textTransform: "capitalize",
      },
      bellIcon: {
        width: 12,
        height: 12,
        marginRight: 3,
      },
    })
);
