import dictionary from "../../i18n/en_US/dictionary";

export enum WidgetTypes {
  Waiting = "waiting",
  Served = "served",
  Cancelled = "cancelled",
  AverageWait = "average_wait",
}

export enum QueueItemStatuses {
  Waiting = "waiting",
  Served = "served",
  Cancelled = "cancelled",
}

type QueueItemStatus =
  | QueueItemStatuses.Waiting
  | QueueItemStatuses.Served
  | QueueItemStatuses.Cancelled;

export enum QueueItemLabels {
  Birthday = "birthday",
  Vip = "vip",
  HasCoupon = "hasCoupon",
  Anniversary = "anniversary",
  FriendOfOwner = "friendOfOwner",
  HasAllergies = "hasAllergies",
  Over21 = "over21",
  Vegan = "vegan",
}

export type QueueItemLabel =
  | "birthday"
  | "vip"
  | "hasCoupon"
  | "anniversary"
  | "friendOfOwner"
  | "hasAllergies"
  | "over21"
  | "vegan";

export interface QueueItem {
  id: number;
  name: string;
  phone: string;
  quantity: number;
  quotedTime: number;
  queuedAt: number; // timestamp when was added to queue
  labels: QueueItemLabel[];
  isNotified: boolean;
  notifiedAt: number; // timestamp when was notified
  status: QueueItemStatus;
  timesUsed: number;
  stoppedAt?: number;
  queuePosition?: number;
}

export interface VenueData {
  name: string;
  queue: QueueItem[];
}

export const LabelsText = {
  [QueueItemLabels.Birthday]: dictionary.guestModal.birthday,
  [QueueItemLabels.Vip]: dictionary.guestModal.vip,
  [QueueItemLabels.HasCoupon]: dictionary.guestModal.hasCoupon,
  [QueueItemLabels.Anniversary]: dictionary.guestModal.anniversary,
  [QueueItemLabels.FriendOfOwner]: dictionary.guestModal.friendOfOwner,
  [QueueItemLabels.HasAllergies]: dictionary.guestModal.hasAllergies,
  [QueueItemLabels.Over21]: dictionary.guestModal.over21,
  [QueueItemLabels.Vegan]: dictionary.guestModal.vegan,
};
