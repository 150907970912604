import { BasicForm } from "../../../../common/models";
import { ValidationRules } from "../../../../common/utils/validation";

export type LoginBasicFormField = "username" | "password";

export interface ILoginBasicForm extends BasicForm<string> {
  errors: {
    [key in LoginBasicFormField]?: string;
  };
  values: {
    [key in LoginBasicFormField]?: string;
  };
  touched: LoginBasicFormField[];
}

export type LoginFormSchemaType = Record<LoginBasicFormField, ValidationRules>;

export const schema: LoginFormSchemaType = {
  username: {
    required: true,
    length: {
      max: 64,
    },
  },
  password: {
    required: true,
    length: {
      max: 128,
    },
  },
};

export const LoginBasicForm: ILoginBasicForm = {
  errors: {
    username: "",
    password: "",
  },
  values: {
    username: "",
    password: "",
  },
  isValid: true,
  touched: [],
};
