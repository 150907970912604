import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../../common/models";
import { colors } from "../../../../../../../../theme/colors";

export type ClassNames =
  | "columnsWrapper"
  | "tableRoot"
  | "actionsColumn"
  | "labelsColumn"
  | "bodyWrapper"
  | "headerRow"
  | "numberSymbol"
  | "drag";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      tableRoot: {
        borderTop: `1px solid ${colors.grey.dividerLighter}`,
        margin: theme.spacing(0, 3),
        marginTop: theme.spacing(1),
        minWidth: 850,
        width: `calc(100% - ${theme.spacing(6)}px)`,
      },
      columnsWrapper: {
        width: "75%",
        margin: "0 auto",

        "& th": {
          fontSize: theme.typography.pxToRem(12),
          color: colors.grey.widgets,
          display: "inline-block",
        },
      },
      actionsColumn: {
        paddingRight: 0,
        [theme.breakpoints.up("lg")]: {
          width: "15%",
        },
      },
      labelsColumn: {
        [theme.breakpoints.up("lg")]: {
          width: "20%",
        },
      },
      bodyWrapper: {
        width: "100%",
      },
      headerRow: {
        display: "flex",
        alignItems: "center",
        position: "relative",
      },
      numberSymbol: {
        color: colors.grey.widgets,
        fontSize: theme.typography.pxToRem(12),
        fontFamily: theme.typography.fontFamily,
        position: "absolute",
        left: "8%",
      },
      drag: {
        color: colors.grey.widgets,
        fontSize: theme.typography.pxToRem(12),
        fontFamily: theme.typography.fontFamily,
        position: "absolute",
        left: "2%",
      },
    })
);
