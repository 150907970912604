export const colors = {
  blue: {
    secondary: "#2886E4",
    light: "#92C9FB",
    violet: "#AC9EFF",
    violetDark: "#6979F8",
  },
  red: {
    primary: "#FE5454",
    light: "rgba(255, 129, 129, 0.2)",
    pink: "#FF8686",
    lightPink: "#FFF0F0",
    lightPinkDarker: "#ffcdcd",
  },
  green: {
    secondary: "#66BD5F",
    lighter: "#79C177",
  },
  grey: {
    elementsLight: "#F7F8FC",
    elementsSecondary: "#F9FAFC",
    primaryDark: "#1D1D1F",
    secondaryDark: "#262628",
    editIconLight: "#596880",
    divider: "#E3E7EC",
    dividerLight: "#ECECEC",
    dividerLighter: "#F2F2F4",
    primary: "#464854",
    secondary: "#A5B2C5",
    light: "#CFD9ED",
    lighten: "#F4F6F8",
    elements: "#E5E5E5",
    activeLink: "#5F6274",
    modalHeader: "#273950",
    modalText: "#585D6E",
    border: "#EBECF0",
    widgets: "#A4A9AE",
    radio: "#E5E7EF",
    inputBackground: "#FCFCFC",
    linkIcon: "#989BAD",
  },
  orange: {
    primary: "#FFB673",
    main: "#FFB546",
    light: "#FFD778",
  },
  white: {
    primary: "#FFFFFF",
  },
};
