import { ClickAwayListener, IconButton, Paper } from "@material-ui/core";
import clsx from "clsx";
import React, { FC, useEffect, useState } from "react";

import Props from "./Props";
import { useStyles } from "./styles";

const IconDropdown: FC<Props> = (props: Props) => {
  const {
    children,
    icon,
    button,
    buttonClassName,
    indicator,
    onOpen,
    onClose,
    className,
    paperClass,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();

  const toggleDropdown = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const hideDropdown = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen && onOpen) {
      onOpen();
    } else if (!isOpen && onClose) {
      onClose();
    }
  }, [isOpen, onClose, onOpen]);

  return (
    <ClickAwayListener onClickAway={hideDropdown}>
      <div className={clsx(classes.root, className)}>
        {(button && button(toggleDropdown, buttonClassName)) || (
          <IconButton
            onClick={toggleDropdown}
            className={clsx(classes.button, buttonClassName)}>
            {icon}
            {indicator && <span className={classes.indicator} />}
          </IconButton>
        )}
        {isOpen && (
          <Paper
            onClick={hideDropdown}
            className={clsx(classes.paperWrapper, paperClass)}>
            {children}
          </Paper>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default IconDropdown;
