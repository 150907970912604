import { ThemeProvider } from "@material-ui/styles";
import React from "react";
import { BrowserRouter } from "react-router-dom";

import AlertContextProvider from "./common/components/Alert/AlertContextProvider";
import AlertsContainer from "./common/components/Alert/AlertsContainer";
import Routes from "./Routes";
import StoreProvider from "./store/store";
import theme from "./theme";

function App() {
  return (
    <StoreProvider>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <AlertContextProvider>
            <>
              <Routes />
              <AlertsContainer />
            </>
          </AlertContextProvider>
        </BrowserRouter>
      </ThemeProvider>
    </StoreProvider>
  );
}

export default App;
