import { Color } from "@material-ui/lab";
import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../models";

export type ClassNames =
  | "alertsContainer"
  | "alertWrapper"
  | "icon"
  | "message"
  | "title"
  | Color;

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      alertsContainer: {
        maxWidth: 500,
        position: "fixed",
        zIndex: 9999,
        top: theme.spacing(2),
        right: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
          maxWidth: "85vw",
        },
      },
      alertWrapper: {
        fontFamily: theme.typography.fontFamily,
        backgroundColor: "#FFF",
        boxShadow: "0 0 25px 0 rgba(0,0,0,0.25)",
        width: 300,
        borderRadius: 6,
        borderLeftWidth: 4,
        borderLeftStyle: "solid",
        "&:not(:first-of-type)": {
          marginTop: theme.spacing(1),
        },
      },
      error: {
        borderLeftColor: theme.palette.error.main,
      },
      info: {
        borderLeftColor: theme.palette.info.main,
      },
      message: {
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(19),
        color: theme.palette.grey.A200,
      },
      icon: {
        alignItems: "center",
      },
      success: {
        borderLeftColor: theme.palette.success.main,
      },
      title: {
        fontSize: theme.typography.pxToRem(16),
        lineHeight: theme.typography.pxToRem(18),
        fontWeight: "bold",
      },
      warning: {
        borderLeftColor: theme.palette.warning.main,
      },
    })
);
