import { Button, CircularProgress } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";

import Props from "./Props";
import { useStyles } from "./styles";

const ButtonPrimary: FC<Props> = (props) => {
  const { children, className, large, loading, ...rest } = props;

  const classes = useStyles();

  return (
    <Button
      className={clsx(className, classes.button, large && classes.buttonLarge)}
      color="primary"
      size="medium"
      type="submit"
      variant="contained"
      {...rest}>
      {children}
      {loading && (
        <CircularProgress className={classes.loader} size={18} thickness={5} />
      )}
    </Button>
  );
};

export default ButtonPrimary;
