import axios, { AxiosResponse } from "axios";
import { Dispatch } from "react";

import { LoginResponse } from "../common/api/auth/models";
import Endpoints from "../common/api/endpoints";
import { excludedRefresh, handleLogoutClick } from "../common/helpers";
import HttpStatusCodes from "../common/httpStatusCodes";
import dictionary from "../i18n/en_US/dictionary";
import { Action } from "../store/models";
import { getRouteWithSlash, Route } from "./router";

export const http = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/waitlist/`,
  withCredentials: true,
});

http.interceptors.request.use((config) => {
  return { ...config };
});

export const subscribeResponse = (
  dispatch: Dispatch<Action>,
  history: any,
  showErrorAlert: (message: string) => void,
  onTokenRefresh: (tokens: LoginResponse) => void
) => {
  http.interceptors.response.use(
    (response) => response,
    (error) => {
      if (location.pathname !== getRouteWithSlash(Route.Login)) {
        if (error.response) {
          const { status, config } = error.response;

          if (config.url === Endpoints.REFRESH_TOKENS) {
            handleLogoutClick(dispatch, history)();
          }

          if (
            (status === HttpStatusCodes.UNAUTHORIZED ||
              status === HttpStatusCodes.FORBIDDEN) &&
            !excludedRefresh(error)
          ) {
            return http
              .post(Endpoints.REFRESH_TOKENS)
              .then((response: AxiosResponse<LoginResponse>) => {
                const { data, status } = response;

                if (status === HttpStatusCodes.OK) {
                  onTokenRefresh(data);

                  return axios(error.config);
                }

                return Promise.reject();
              })
              .catch(handleLogoutClick(dispatch, history));
          } else if (status === HttpStatusCodes.FORBIDDEN) {
            showErrorAlert(dictionary.app.permissionsError);
          } else if (error.response.data.isJoi) {
            showErrorAlert(dictionary.app.validationError);
          } else {
            showErrorAlert(error.response.data);
          }
        } else {
          showErrorAlert(dictionary.app.networkError);
        }
      }

      return Promise.reject(error);
    }
  );
};
