import React, { createContext, useCallback, useReducer } from "react";

import { hasAccessToken, mfaTokenKey } from "../common/constants";
import Actions from "./actions";
import { Action, InitialState, Props, ProvidedContext } from "./models";

const initialStore: InitialState = {
  hasToken: !!localStorage.getItem(hasAccessToken),
  mfaToken: localStorage.getItem(mfaTokenKey),
  venueId: Number(localStorage.getItem("venueId")) || null,
  settings: null,
  isLoading: false,
  lockingRequest: false,
  customers: [],
  customerWaitTimes: {},
  queuePositions: {},
};
export const store = createContext<ProvidedContext>({
  state: initialStore,
  dispatch: () => null,
});

function StoreProvider(props: Props) {
  const { children } = props;
  const { Provider } = store;

  const mergeToState = useCallback(
    (state: InitialState, field: keyof InitialState, action: Action) => {
      return { ...state, [field]: action.payload };
    },
    []
  );

  const [state, dispatch] = useReducer(
    (state: InitialState, action: Action) => {
      switch (action.type) {
        case Actions.SetAuthTokens:
          const { hasToken } = action.payload || {};
          if (hasToken) {
            localStorage.setItem(hasAccessToken, hasToken);
          } else {
            localStorage.removeItem(hasAccessToken);
          }
          return {
            ...state,
            hasToken: !!hasToken,
          };
        case Actions.SetMfaToken:
          if (action.payload) {
            localStorage.setItem(mfaTokenKey, action.payload);
          } else {
            localStorage.removeItem(mfaTokenKey);
          }
          return mergeToState(state, "mfaToken", action);
        case Actions.SetVenueId:
          return mergeToState(state, "venueId", action);
        case Actions.SetSettings:
          return mergeToState(state, "settings", action);
        case Actions.SetIsLoading:
          return mergeToState(state, "isLoading", action);
        case Actions.SetCustomers:
          return mergeToState(state, "customers", action);
        case Actions.SetLockingRequest:
          return mergeToState(state, "lockingRequest", action);
        case Actions.SetWaitingTimes:
          if (action.meta?.replace) {
            return {
              ...state,
              customerWaitTimes: action.payload,
            };
          }
          return {
            ...state,
            customerWaitTimes: {
              ...state.customerWaitTimes,
              ...action.payload,
            },
          };
        case Actions.SetQueuePositions:
          return {
            ...state,
            queuePositions: {
              ...state.queuePositions,
              ...action.payload,
            },
          };
        default:
          return state;
      }
    },
    initialStore
  );

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
}

export default StoreProvider;
