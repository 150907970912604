import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../common/models";
import { colors } from "../../theme/colors";

export type ClassNames =
  | "contentWrapper"
  | "title"
  | "infoCard"
  | "cards"
  | "minLabel"
  | "controls"
  | "footer"
  | "cancelButton"
  | "cancelModal"
  | "menuButton";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      contentWrapper: {
        width: "100%",
        height: "100%",
        backgroundColor: colors.grey.primaryDark,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        "& > *": {
          width: "100%",
        },
        padding: theme.spacing(3),
      },
      title: {
        textAlign: "center",
        height: "25%",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: colors.white.primary,
        fontWeight: 700,
        fontFamily: theme.typography.fontFamily,
        "& > span:first-child": {
          fontSize: theme.typography.pxToRem(26),
          marginBottom: theme.spacing(2),
        },
        "& > span:last-child": {
          fontSize: theme.typography.pxToRem(18),
        },
      },
      infoCard: {
        backgroundColor: colors.grey.secondaryDark,
        borderRadius: 4,
        width: "47%",
        height: "100%",
        color: colors.white.primary,
        fontWeight: 600,
        fontFamily: theme.typography.fontFamily,
        "& > span:first-child": {
          fontSize: theme.typography.pxToRem(34),
        },
        "& > span:last-child": {
          fontSize: theme.typography.pxToRem(14),
        },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-evenly",
        padding: theme.spacing(3),
        textAlign: "center",
      },
      cards: {
        justifyContent: "space-between",
        height: "30%",
        marginBottom: theme.spacing(6),
      },
      minLabel: {
        fontSize: theme.typography.pxToRem(18),
      },
      controls: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-evenly",
        height: "25%",
        "& *": {
          width: "100%",
          borderRadius: 4,
        },
        "& > button:last-child": {
          backgroundColor: colors.grey.primary,
        },
      },
      footer: {
        height: "5%",
        textAlign: "center",
        position: "absolute",
        bottom: 0,
        color: colors.grey.secondary,
        fontSize: theme.typography.pxToRem(14),
        fontFamily: theme.typography.fontFamily,
      },
      cancelButton: {
        "&:hover": {
          backgroundColor: colors.green.secondary,
        },
        border: 0,
        backgroundColor: colors.green.secondary,
        color: colors.white.primary,
      },
      cancelModal: {
        backgroundColor: colors.grey.secondaryDark,
        color: colors.white.primary,
        "& > div": {
          border: 0,
        },
        "& p": {
          color: colors.white.primary,
        },
      },
      menuButton: {
        backgroundColor: `${colors.green.secondary} !important`,
        "&:hover": {
          backgroundColor: colors.green.secondary,
        },
      },
    })
);
