import { Grid, Typography } from "@material-ui/core";
import React, { FC } from "react";

import Logo from "../../../../common/components/Logo";
import { ReactComponent as DefaultVenuePhoto } from "../../../../common/svg/default-venue-photo.svg";
import dictionary from "../../../../i18n/en_US/dictionary";
import Props from "./Props";
import { useStyles } from "./styles";

const SelectVenueList: FC<Props> = (props: Props) => {
  const { venues, onVenueClick } = props;
  const classes = useStyles();

  return (
    <div className={classes.contentWrapper}>
      <div className={classes.title}>
        <Logo className={classes.logo} width={84} height={84} />
        <Typography variant="h1">{dictionary.settings.selectVenue}</Typography>
      </div>
      <Grid container className={classes.venueItemsContainer}>
        {venues.map(({ id, name, photos }) => {
          const photo = photos.find(({ tag }) => tag === "exterior");
          const photoUrl = photo ? photo.url : null;

          return (
            <Grid
              onClick={() => onVenueClick(id)}
              item
              key={id}
              className={classes.venueItemWrapper}
              xs={12}
              sm={6}
              md={4}
              lg={3}
              xl="auto">
              <div className={classes.venueItem}>
                {photoUrl ? (
                  <img
                    src={photoUrl}
                    alt={name}
                    className={classes.venueImage}
                  />
                ) : (
                  <DefaultVenuePhoto />
                )}
                <Typography variant="h3" className={classes.venueName}>
                  {name}
                </Typography>
              </div>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default SelectVenueList;
