import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";
import { colors } from "../../../../theme/colors";

export type ClassNames =
  | "logo"
  | "contentWrapper"
  | "title"
  | "venues"
  | "venueItem"
  | "venueName"
  | "venueItemWrapper"
  | "venueItemsContainer"
  | "venueImage";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      logo: {
        marginBottom: theme.spacing(3),
        "& > g": {
          fill: colors.red.primary,
        },
      },
      contentWrapper: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
      title: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        "& > *": {
          margin: "0 auto",
        },
        margin: theme.spacing(5, 0),
      },
      venues: {},
      venueItem: {
        display: "flex",
        backgroundColor: colors.grey.secondary,
        borderRadius: 6,
        position: "relative",
        textAlign: "center",
        width: "100%",
        height: "100%",
        "& > svg": {
          margin: "auto",
        },
      },
      venueName: {
        color: colors.white.primary,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
      venueItemWrapper: {
        width: 315,
        height: 230,
        padding: theme.spacing(1),
        cursor: "pointer",
      },
      venueItemsContainer: {
        overflowY: "auto",
        padding: theme.spacing(1),
        width: "85%",
        [theme.breakpoints.down(450)]: {
          width: "100%",
        },
      },
      venueImage: {
        objectFit: "cover",
        width: "100%",
        height: "100%",
        borderRadius: 6,
      },
    })
);
