import { CircularProgress } from "@material-ui/core";
import React, { FC, useCallback, useContext, useEffect, useRef } from "react";
import { Redirect, Switch, useHistory } from "react-router-dom";

import Endpoints from "./common/api/endpoints";
import { SettingsResponse } from "./common/api/settings/models";
import { SETTINGS } from "./common/api/settings/requests";
import { AlertContext } from "./common/components/Alert/AlertContextProvider";
import PrivateRoute from "./common/components/PrivateRoute";
import RouteWithLayout from "./common/components/RouteWithLayout";
import UnderConstruction from "./common/components/UnderContsruction";
import { hasAccessToken } from "./common/constants";
import { handleLogoutClick } from "./common/helpers";
import { useRestApi } from "./common/hooks/useRestApi";
import { useGlobalStyles } from "./common/styles";
import { subscribeResponse } from "./config/api";
import { getRouteWithSlash, Route } from "./config/router";
import dictionary from "./i18n/en_US/dictionary";
import MainLayout from "./layouts/Main";
import MinimalLayout from "./layouts/Minimal";
import { actions, store } from "./store";
import Actions from "./store/actions";
import Dashboard from "./views/Dashboard";
import Login from "./views/Login";
import QueueInfo from "./views/QueueInfo";
import Settings from "./views/Settings";
import PasswordModal from "./views/Settings/components/PasswordModal";

const Routes: FC = () => {
  const { showAlert } = useContext(AlertContext);
  const { state, dispatch } = useContext(store);
  const history = useHistory();
  const isSubscribed = useRef(false);
  const { hasToken } = state;

  const isLogin =
    history.location.pathname === (getRouteWithSlash(Route.Login) as string);
  const publicPage = history.location.pathname.match(/\/waitlist\//gi);

  const showErrorMessage = useCallback(
    (message: string) => {
      showAlert(message, "error");
    },
    [showAlert]
  );

  const [, logout] = useRestApi<boolean, { venueId: number }>(
    Endpoints.LOGOUT,
    "POST"
  );

  const onLogout = useCallback(async () => {
    dispatch({ type: actions.SetIsLoading, payload: true });
    history.push(Route.Login);
    await logout({ venueId: state.venueId as number });
    handleLogoutClick(dispatch, history)();
    dispatch({ type: actions.SetIsLoading, payload: false });
  }, [dispatch, history, logout, state.venueId]);

  useEffect(() => {
    window.addEventListener("storage", (e) => {
      if (e.key === hasAccessToken) {
        onLogout();
      }
    });
  }, [dispatch, history, onLogout]);

  useEffect(() => {
    if (!publicPage && !state.venueId) {
      handleLogoutClick(dispatch, history)();
    }
  }, [dispatch, history, publicPage, state.venueId]);

  if (!isSubscribed.current) {
    subscribeResponse(
      dispatch,
      history,
      (message: string) => {
        showErrorMessage(message);
      },
      (data) => {
        dispatch({
          type: Actions.SetAuthTokens,
          payload: {
            hasToken: !!data,
          },
        });
      }
    );
    isSubscribed.current = true;
  }

  const [settings, fetchSettings] = useRestApi<SettingsResponse>(
    SETTINGS(state.venueId as number),
    "GET"
  );

  useEffect(() => {
    if (!isLogin && !publicPage && state.venueId && hasToken) {
      (async () => {
        const data = await fetchSettings();
        dispatch({ type: Actions.SetSettings, payload: data || {} });
      })();
    }
  }, [dispatch, hasToken, fetchSettings, isLogin, publicPage, state.venueId]);

  const globalClasses = useGlobalStyles();

  return !publicPage && (settings.loading || state.isLoading) ? (
    <CircularProgress
      size="3rem"
      thickness={5}
      className={globalClasses.marginAuto}
    />
  ) : (
    <>
      <PasswordModal
        title={dictionary.security.enterPasscode}
        open={!!state.settings?.isLocked || state.lockingRequest}
      />
      <Switch>
        <Redirect exact from="/" to={getRouteWithSlash(Route.Dashboard)} />
        <RouteWithLayout
          component={Login}
          className={"no-header"}
          layout={MinimalLayout}
          path={getRouteWithSlash(Route.Login)}
          exact
        />
        <PrivateRoute
          component={Dashboard}
          layout={MainLayout}
          className={"no-header no-padding"}
          path={getRouteWithSlash(Route.Dashboard)}
          exact
        />
        <PrivateRoute
          component={Settings}
          className={"no-header"}
          layout={MainLayout}
          path={getRouteWithSlash(Route.Settings)}
          exact
        />
        <PrivateRoute
          component={UnderConstruction}
          className={"no-header"}
          layout={MainLayout}
          path={getRouteWithSlash(Route.About)}
          exact
        />
        <RouteWithLayout
          component={QueueInfo}
          className={"no-header"}
          layout={MinimalLayout}
          path={getRouteWithSlash(Route.Waitlist)}
          exact
        />
      </Switch>
    </>
  );
};

export default Routes;
