import { Typography } from "@material-ui/core";
import { SvgIcon } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";

import Logo from "../../common/components/Logo";
import OcietyNavLink from "../../common/components/OcietyNavLink";
import { useGlobalStyles } from "../../common/styles";
import { ReactComponent as EmailIcon } from "../../common/svg/icons/email.svg";
import { HELP_EMAIL } from "../../config";
import { Route } from "../../config/router";
import Props from "./Props";
import { useStyles } from "./styles";

const Minimal: FC<Props> = (props: Props) => {
  const { children, className } = props;
  const globalClasses = useGlobalStyles();
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <header className={classes.header}>
        <OcietyNavLink
          to={Route.Home}
          noEffects
          linkTextClassName={globalClasses.flexRowStartCenter}>
          <Logo withText />
        </OcietyNavLink>
        <a
          href={`mailto:${HELP_EMAIL}`}
          className={globalClasses.flexRowStartCenter}>
          <SvgIcon width={24} height={18} viewBox="0 0 24 18">
            <EmailIcon />
          </SvgIcon>
          <Typography className={classes.contactLink}>{HELP_EMAIL}</Typography>
        </a>
      </header>
      <main className={classes.content}>{children}</main>
    </div>
  );
};

export default Minimal;
