import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";

export type ClassNames =
  | "addIcon"
  | "content"
  | "addButton"
  | "backgroundImage"
  | "topNav";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      addIcon: {
        marginRight: 20,
      },
      addButton: {
        maxWidth: 215,
        minHeight: 60,
        marginTop: 40,
      },
      content: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& img": {
          marginTop: "10%",
          maxWidth: "90%",
          [theme.breakpoints.down("sm")]: {
            marginTop: "15%",
          },
          [theme.breakpoints.down("xs")]: {
            marginTop: "20%",
          },
          [theme.breakpoints.down(480)]: {
            marginTop: "30%",
          },
          [theme.breakpoints.down(375)]: {
            marginTop: "40%",
          },
        },
      },
      backgroundImage: {
        marginBottom: 60,
      },
      topNav: {
        margin: theme.spacing(3),
        width: `calc(100% - ${theme.spacing(6)}px)`,
      },
    })
);
