import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { colors } from "../../../theme/colors";
import { OcietyTheme } from "../../models";

export type ClassNames = "root" | "paperWrapper" | "indicator" | "button";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {},
      button: {
        position: "relative",
      },
      indicator: {
        background: colors.blue.violetDark,
        border: `1.5px solid ${colors.grey.elementsLight}`,
        borderRadius: "50%",
        top: 10,
        right: 15,
        position: "absolute",
        height: 10,
        width: 10,
      },
      paperWrapper: {
        zIndex: 10,
        minWidth: 253,
        padding: theme.spacing(20 / 8),
        position: "absolute",
        top: "100%",
        marginTop: 10,
        right: -12,
        borderRadius: 8,
      },
    })
);
